import React, { useMemo } from 'react';
import cn from 'classnames';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';

export enum TooltipPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface SimpleTooltipProps {
  /**
   * The title of the tooltip
   */
  title?: string;

  /**
   * The description of the tooltip
   */
  description?: string;

  /**
   * The trigger of the tooltip
   */
  children: React.ReactNode;

  /**
   * The position of the tooltip
   */
  position?: TooltipPosition;

  /**
   * Set if the tooltip is going to be cotrolled by the open property
   */
  controlled?: boolean;

  /**
   * Set if a tooltip is going to be open, this option is only avaylable when controlled is active
   */
  open?: boolean;

  /**
   * The class name of the title
   */
  titleClassName?: string;

  /**
   * The class name of the content
   */
  contentClassName?: string;

  /**
   * The class name of the description
   */
  descriptionClassName?: string;

  /**
   * Callback when the content was clicked
   */
  onClick?: (event: React.MouseEvent) => void;

  /**
   * Callback when the tootip changed it's state
   */
  onOpenChange?: (open: boolean) => void;

  /**
   * Prevents the tooltip from closing when the user clicks the trigger
   */
  preventClose?: boolean;

  /**
   * The delay duration of the tooltip
   */
  delayDuration?: number;
}

export const SimpleTooltip = ({
  open,
  title,
  onClick,
  children,
  controlled,
  description,
  onOpenChange,
  titleClassName,
  contentClassName,
  descriptionClassName,
  preventClose = false,
  delayDuration = 700,
  position = TooltipPosition.RIGHT,
}: SimpleTooltipProps) => {
  const classes = {
    content: cn('e-flex e-break-all e-flex-col e-max-w-xs e-m-1', contentClassName),
    title: cn('e-mb-1', titleClassName),
    description: cn(descriptionClassName),
  };

  const handlePointerDown = (event: { preventDefault: () => void }) => {
    if (preventClose) event.preventDefault();
  };

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) onClick(event);
  };

  const handleOnOpenChange = (open: boolean) => {
    if (onOpenChange) onOpenChange(open);
  };

  const rootProperties = useMemo(() => {
    if (controlled)
      return {
        onOpenChange: handleOnOpenChange,
        controlled,
        open,
      };
    return { onOpenChange: handleOnOpenChange };
  }, [controlled, open]);

  return (
    <BaseTooltip.Provider delayDuration={delayDuration}>
      <BaseTooltip.Root {...rootProperties}>
        <BaseTooltip.Trigger asChild>{children}</BaseTooltip.Trigger>
        <BaseTooltip.Portal>
          <BaseTooltip.Content
            side={position}
            className={classes.content}
            onPointerDownOutside={handlePointerDown}
            onClick={handleClick}>
            {title && <BaseTooltip.Title className={classes.title}>{title}</BaseTooltip.Title>}
            {description && (
              <BaseTooltip.Description className={classes.description}>{description}</BaseTooltip.Description>
            )}
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
};

export default SimpleTooltip;

/**
 * Notes:
 * In order to prevent the tooltip from closing when the trigger is clicked, the onPointerDownOutside event must be used.
 * Don't forget to add the e.preventDefault() on the trigger element.
 * @reference https://github.com/radix-ui/primitives/issues/2029
 */
