import { CSSProperties } from 'react';

/**
 * getMaxHeightByItems
 * @description - Set the max height by a list of items
 * @returns {CSSProperties}
 */
export const getMaxHeightByItems = (listLength: number, maxItems: number, maxHeight: string): CSSProperties => {
  const calcHeight = listLength <= maxItems ? 'auto' : maxHeight;
  return { height: calcHeight };
};
