import React from 'react';
import cn from 'classnames';
import { useToast } from '../../../common/contexts';
import { Toast } from './Toast';

export enum ToastPlacement {
  top = 'top',
  bottom = 'bottom',
  bottomEnd = 'bottom-end',
}

const Placement: Record<ToastPlacement, string> = {
  [ToastPlacement.top]: 'e-top-8 e-left-1/2 -e-translate-x-1/2 e-transform',
  [ToastPlacement.bottom]: 'e-bottom-8 e-left-1/2 -e-translate-x-1/2 e-transform',
  [ToastPlacement.bottomEnd]: 'e-bottom-8 e-right-8',
};

export interface ToastContainerProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The position (relative to the target) at which the toast should appear.
   */
  placement?: ToastPlacement;
}

/**
 * The main container for all the Toasts in the app.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2023-08-23
 */
export const ToastContainer = ({ className, placement = ToastPlacement.bottomEnd }: ToastContainerProps) => {
  const classes = cn(className, 'e-fixed e-z-40 e-space-y-4', Placement[placement]);
  const { currentToasts } = useToast();

  if (currentToasts.length === 0) return null;

  const wrapperToast = currentToasts.map((toast) => (
    <div key={toast.id} className="e-flex e-w-full">
      <Toast {...toast} />
    </div>
  ));

  /* RENDER */
  return <div className={classes}>{wrapperToast}</div>;
};
