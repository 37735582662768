import React from 'react';
import { CompanyAndProductLogoSize } from './CompanyAndProductLogoSize';

export interface PacsProps {
  /**
   * Specify the size of the logo.
   * @default md
   */
  size?: CompanyAndProductLogoSize;
}

const PacsSizes: Record<CompanyAndProductLogoSize, { width: number; height: number }> = {
  [CompanyAndProductLogoSize.md]: {
    width: 148,
    height: 34,
  },
  [CompanyAndProductLogoSize.sm]: {
    width: 90,
    height: 20,
  },
};

/**
 * Pacs component for the pac's product.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-07-17
 */
export const Pacs = ({ size = CompanyAndProductLogoSize.md }: PacsProps) => {
  return (
    <svg
      fill="currentColor"
      width={PacsSizes[size].width}
      height={PacsSizes[size].height}
      viewBox="0 0 148 34"
      preserveAspectRatio="xMinYMin meet"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7181 25.7565C24.4916 25.7565 22.6192 24.9253 21.1027 23.2641C19.5851 21.6035 18.8271 19.592 18.8271 17.2313C18.8271 14.8712 19.5851 12.8603 21.1027 11.1985C22.6192 9.53789 24.4916 8.70674 26.7181 8.70674C27.8313 8.70674 28.8573 8.95319 29.7959 9.44445C30.734 9.93625 31.4543 10.5324 31.9569 11.2319V0.837891H35.5586V25.4281H31.9569V23.2313C31.4543 23.9313 30.734 24.527 29.7959 25.0182C28.8573 25.51 27.8313 25.7565 26.7181 25.7565ZM23.8689 20.969C24.7863 21.975 25.9541 22.4778 27.3729 22.4778C28.7918 22.4778 29.9591 21.975 30.8764 20.969C31.7932 19.9641 32.2516 18.7182 32.2516 17.2313C32.2516 15.7455 31.7932 14.4996 30.8764 13.4936C29.9591 12.4893 28.7918 11.9854 27.3729 11.9854C25.9541 11.9854 24.7863 12.4893 23.8689 13.4936C22.9527 14.4996 22.4943 15.7455 22.4943 17.2313C22.4943 18.7182 22.9527 19.9641 23.8689 20.969Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54586 25.7565C6.05741 25.7565 4.01099 24.9581 2.40659 23.3625C0.802197 21.7674 0 19.7231 0 17.2313C0 14.8051 0.807108 12.7783 2.42296 11.1499C4.03773 9.52149 6.07869 8.70674 8.54586 8.70674C10.816 8.70674 12.6982 9.42805 14.194 10.8712C15.6892 12.3133 16.4369 14.3024 16.4369 16.8384C16.4369 17.2756 16.4254 17.669 16.4041 18.0188H3.53622C3.62298 19.3516 4.1365 20.4286 5.07512 21.2483C6.0132 22.0679 7.18157 22.4778 8.5786 22.4778C10.6741 22.4778 12.2349 21.6466 13.2608 19.9854L16.0112 21.9526C14.4395 24.4887 11.9511 25.7565 8.54586 25.7565ZM3.69993 15.3953H12.8024C12.606 14.2592 12.0984 13.3685 11.2799 12.7231C10.4613 12.0794 9.50631 11.7559 8.41488 11.7559C7.2798 11.7559 6.27023 12.0734 5.38618 12.7067C4.50213 13.3412 3.94004 14.2373 3.69993 15.3953Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2886 23.4439C42.893 25.0395 44.9394 25.8379 47.4279 25.8379C50.8331 25.8379 53.3216 24.5701 54.8932 22.0341L52.1428 20.0669C51.1169 21.7281 49.5561 22.5592 47.4606 22.5592C46.0636 22.5592 44.8952 22.1494 43.9571 21.3297C43.0185 20.51 42.505 19.433 42.4182 18.1002H55.2861C55.3074 17.7505 55.3189 17.357 55.3189 16.9199C55.3189 14.3838 54.5712 12.3947 53.076 10.9526C51.5802 9.50947 49.698 8.78816 47.4279 8.78816C44.9607 8.78816 42.9197 9.60292 41.305 11.2313C39.6891 12.8597 38.882 14.8865 38.882 17.3128C38.882 19.8046 39.6842 21.8488 41.2886 23.4439ZM51.6844 15.4767H42.5819C42.822 14.3188 43.3841 13.4226 44.2682 12.7882C45.1522 12.1548 46.1618 11.8373 47.2969 11.8373C48.3883 11.8373 49.3433 12.1608 50.1619 12.8046C50.9804 13.4499 51.488 14.3406 51.6844 15.4767Z"
      />
      <path d="M58.1184 9.03461H61.7201V11.2641C62.7455 9.5592 64.4044 8.7062 66.697 8.7062C68.5955 8.7062 70.1126 9.32969 71.2482 10.5751C72.3827 11.822 72.9508 13.4827 72.9508 15.5592V25.4281H69.3486V16.1499C69.3486 14.8379 69.0424 13.8166 68.4318 13.0838C67.8206 12.3516 66.9916 11.9849 65.9439 11.9849C64.6997 11.9849 63.6841 12.4559 62.8988 13.3947C62.1124 14.3352 61.7201 15.6576 61.7201 17.3625V25.4281H58.1184V9.03461Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.8365 11.8618C95.3375 10.3464 93.4436 9.58875 91.1547 9.58875C88.8658 9.58875 86.9719 10.3464 85.4729 11.8618C85.4493 11.8852 85.4302 11.9086 85.4111 11.932L85.3944 11.9522L85.3818 11.9671V9.86471H83.2549V16.7591C83.2397 16.9748 83.2245 17.1906 83.2245 17.4164C83.2245 17.6422 83.2397 17.8579 83.2549 18.0737V18.2192H83.26C83.4221 20.0607 84.1614 21.6463 85.4729 22.971C86.9719 24.4863 88.8658 25.244 91.1547 25.244C93.4436 25.244 95.3375 24.4863 96.8365 22.971C98.3354 21.4556 99.0849 19.6041 99.0849 17.4164C99.0849 15.2287 98.3354 13.3771 96.8365 11.8618ZM95.3072 21.5861C94.2437 22.7201 92.8613 23.2871 91.1598 23.2871C89.4583 23.2871 88.0758 22.7201 87.0124 21.5861C85.949 20.4521 85.4172 19.0622 85.4172 17.4164C85.4172 15.7706 85.949 14.3807 87.0124 13.2467C88.0758 12.1127 89.4583 11.5457 91.1598 11.5457C92.8613 11.5457 94.2437 12.1127 95.3072 13.2467C96.3706 14.3807 96.9023 15.7706 96.9023 17.4164C96.8972 19.0622 96.3655 20.4521 95.3072 21.5861Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.818 25.244C106.691 25.244 104.898 24.4813 103.44 22.9559C101.981 21.4305 101.252 19.584 101.252 17.4164C101.252 15.2487 101.981 13.4022 103.44 11.8768C104.898 10.3514 106.691 9.58875 108.818 9.58875C109.972 9.58875 111.061 9.84966 112.084 10.3715C113.107 10.8933 113.892 11.5758 114.439 12.4187V9.88983H116.566V24.9429H114.439V22.414C113.892 23.257 113.107 23.9394 112.084 24.4612C111.061 24.9831 109.972 25.244 108.818 25.244ZM105.004 21.5861C106.048 22.7201 107.39 23.2871 109.03 23.2871C110.671 23.2871 112.013 22.7201 113.056 21.5861C114.099 20.4521 114.621 19.0622 114.621 17.4164C114.621 15.7706 114.099 14.3807 113.056 13.2467C112.013 12.1127 110.671 11.5457 109.03 11.5457C107.39 11.5457 106.048 12.1127 105.004 13.2467C103.961 14.3807 103.44 15.7706 103.44 17.4164C103.44 19.0622 103.961 20.4521 105.004 21.5861Z"
      />
      <path d="M127.838 25.244C125.549 25.244 123.655 24.4863 122.156 22.971C120.657 21.4556 119.908 19.6041 119.908 17.4164C119.908 15.2287 120.657 13.3771 122.156 11.8618C123.655 10.3464 125.549 9.58875 127.838 9.58875C129.377 9.58875 130.75 9.94 131.955 10.6425C133.16 11.345 134.107 12.3083 134.796 13.5327L132.942 14.5864C132.456 13.6431 131.773 12.9004 130.892 12.3585C130.01 11.8166 128.993 11.5457 127.838 11.5457C126.136 11.5457 124.754 12.1127 123.691 13.2467C122.627 14.3807 122.095 15.7706 122.095 17.4164C122.095 19.0622 122.627 20.4521 123.691 21.5861C124.754 22.7201 126.136 23.2871 127.838 23.2871C128.993 23.2871 130.01 23.0161 130.892 22.4742C131.773 21.9323 132.456 21.1897 132.942 20.2463L134.796 21.3001C134.107 22.5244 133.16 23.4878 131.955 24.1903C130.75 24.8928 129.377 25.244 127.838 25.244Z" />
      <path d="M139.125 24.2956C140.169 24.9279 141.409 25.244 142.847 25.244C144.367 25.244 145.597 24.8576 146.539 24.0849C147.481 23.3122 147.952 22.2334 147.952 20.8485C147.952 18.7812 146.605 17.326 143.911 16.4831L142.179 15.9412C140.599 15.4394 139.809 14.6265 139.809 13.5025C139.809 12.9205 140.093 12.4338 140.66 12.0424C141.227 11.651 141.885 11.4553 142.635 11.4553C144.053 11.4553 145.167 12.0675 145.977 13.2918L147.678 12.2381C146.686 10.4719 145.045 9.58875 142.756 9.58875C141.298 9.58875 140.088 9.95004 139.125 10.6726C138.163 11.3951 137.682 12.3686 137.682 13.5929C137.682 15.7003 138.898 17.1253 141.328 17.868L142.969 18.3798C144.002 18.721 144.736 19.0973 145.172 19.5088C145.607 19.9202 145.825 20.427 145.825 21.0291C145.825 21.7516 145.547 22.3187 144.99 22.7301C144.432 23.1416 143.729 23.3473 142.878 23.3473C141.966 23.3473 141.131 23.1165 140.371 22.6549C139.612 22.1932 139.11 21.561 138.867 20.7582L136.953 21.5409C137.358 22.7452 138.082 23.6634 139.125 24.2956Z" />
      <path d="M81.4672 31.1548C82.48 31.1548 83.3004 30.3419 83.3004 29.3384V23.5429C83.7966 24.1802 84.5714 25.1286 85.3259 25.4999V29.3384C85.3259 31.4458 83.5941 33.1619 81.4672 33.1619H80.9507V31.1548H81.4672Z" />
    </svg>
  );
};
