import React from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../Icon/Icon';

export enum AlertCardVariant {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  neutral = 'neutral',
}

export enum AlertCardSizes {
  sm = 'sm',
  base = 'base',
  fluid = 'fluid',
}

const IconVariants: Record<AlertCardVariant, string> = {
  [AlertCardVariant.success]: 'e-text-success-500',
  [AlertCardVariant.warning]: 'e-text-warning-500',
  [AlertCardVariant.error]: 'e-text-error-500',
  [AlertCardVariant.info]: 'e-text-primary-400',
  [AlertCardVariant.neutral]: 'e-text-neutral-50',
};

const SizesVariants: Record<AlertCardSizes, string> = {
  [AlertCardSizes.sm]: 'e-w-81 e-max-width-81',
  [AlertCardSizes.base]: 'e-w-162 e-max-width-162',
  [AlertCardSizes.fluid]: 'e-w-full',
};

export interface AlertCardProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Class to be added to the component
   */
  className?: string;
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;
  /**
   * Specify an icon to be displayed in the card
   */
  icon?: IconCatalog;
  /**
   * Specify the size of the icon ex. `32` is applied as 32px
   */
  iconSize?: number;
  /**
   * Whether the card has a border or not
   */
  hasBorder?: boolean;
  /**
   * The title of the card
   */
  title: string;
  /**
   * The summary of the card
   */
  body: string | React.ReactNode;
  /**
   * The variant of the card. It determines the importante in the hierarchy
   */
  variant?: AlertCardVariant;
  /**
   * The size of the card
   */
  size?: AlertCardSizes;
  /**
   * Define a section to include actions
   */
  actions?: React.ReactNode;
}

/**
 * An AlertCard is a Card that is used to display alert messages
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-02-22
 */
export const AlertCard = ({
  className,
  icon,
  iconSize = 32,
  hasBorder = true,
  size = AlertCardSizes.base,
  title,
  body,
  variant = AlertCardVariant.neutral,
  actions,
}: AlertCardProps) => {
  const classes = {
    container: cn('e-bg-neutral-900 e-p-6', className, SizesVariants[size], {
      'e-border e-rounded-xl e-border-neutral-500': hasBorder,
    }),
    icon: cn('e-relative', IconVariants[variant]),
    title: cn('e-text-lg e-font-medium e-text-neutral-50 e-text-center e-leading-tight'),
    bodyContainer: cn('e-text-neutral-200 e-leading-5'),
  };

  return (
    <div className={classes.container}>
      <div className="e-flex e-flex-col e-space-y-4">
        <div className="e-flex e-flex-col e-space-y-2 e-text-lg e-items-center">
          <div className="e-flex e-flex-col e-space-y-3 e-items-center">
            {icon ? <Icon className={classes.icon} icon={icon} width={iconSize} height={iconSize} /> : null}
            <span className={classes.title}>{title}</span>
          </div>
          <div className={classes.bodyContainer}>
            {typeof body === 'string' ? (
              <p className="e-text-center e-text-sm e-font-light e-leading-5">{body}</p>
            ) : (
              body
            )}
          </div>
        </div>
        {actions ? <div className="e-flex e-justify-center">{actions}</div> : null}
      </div>
    </div>
  );
};
