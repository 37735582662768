import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { Input } from '../../../common/types/input';
import cn from 'classnames';

export interface RadioProps extends Input, InputHTMLAttributes<HTMLInputElement> {
  /**
   * Specify an optional className to be added to the input tag
   */
  inputClassName?: string;

  /**
   * Specify if it's checked or not
   */
  checked?: boolean;
}

/**
 * Use Radio to select a single option from a list. Radio should be used instead of checkboxes if only one item can be selected from a list.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-08-10
 */
export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id,
      name,
      label,
      value,
      inputClassName,
      className,
      checked = false,
      isDisabled = false,
      onChange,
      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      container: cn(className, 'e-flex e-items-center', {
        'e-cursor-pointer': !isDisabled,
      }),
      input: cn(
        inputClassName,
        'checked:e-border-primary-500 checked:e-border-4 checked:e-bg-base-white',
        'e-rounded-full e-min-w-4 e-h-4 e-border-2 e-border-neutral-50 focus:e-outline-none e-appearance-none',
        {
          'e-opacity-60 e-pointer-events-none': isDisabled,
        },
      ),
      label: cn('e-ml-2 e-text-sm e-font-medium e-text-neutral-50'),
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) return;
      if (onChange) onChange(event);
    };

    return (
      <div className={classes.container}>
        <input
          id={id}
          ref={ref}
          type="radio"
          name={name}
          className={classes.input}
          checked={checked}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          {...restOfProps}
        />
        {label && (
          <label htmlFor={id} className={classes.label}>
            {label}
          </label>
        )}
      </div>
    );
  },
);
