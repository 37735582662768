import React from 'react';
import cn from 'classnames';

export interface TooltipTitleProps {
  /**
   * Elements to display inside the Modal.
   */
  children: React.ReactNode;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;
}

/**
 * `Title` represents the title section of a tooltip
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-09-13
 **/
export const Title = ({ className, children }: TooltipTitleProps) => {
  const classes = cn('e-text-neutral-300 e-text-xs e-font-semi-bold', className);

  return <span className={classes}>{children}</span>;
};
