import React from 'react';
import {
  DropdownMenu as RadixDropdownMenu,
  DropdownMenuProps,
  Trigger,
  Portal,
  Sub,
  Root,
} from '@radix-ui/react-dropdown-menu';
import { Content, Item, CheckboxItem, RadioItem, Separator, SubTrigger, Arrow, SubContent, Label } from './components';

export const DropdownMenu = (props: DropdownMenuProps) => <RadixDropdownMenu {...props} />;
DropdownMenu.Label = Label;
DropdownMenu.Content = Content;
DropdownMenu.Item = Item;
DropdownMenu.CheckboxItem = CheckboxItem;
DropdownMenu.RadioItem = RadioItem;
DropdownMenu.Separator = Separator;
DropdownMenu.SubTrigger = SubTrigger;
DropdownMenu.Arrow = Arrow;
DropdownMenu.SubContent = SubContent;
DropdownMenu.Trigger = Trigger;
DropdownMenu.Portal = Portal;
DropdownMenu.Sub = Sub;
DropdownMenu.Root = Root;
