/**
 * addClassByDataId
 * @description - Add class to element by id
 */
export const addClassByDataId = (dataKey: string, id: string, classes: Array<string> | string) => {
  const element = document.querySelector(`[data-${dataKey}="${id}"]`);
  if (!element) return;

  element.classList.add(...(Array.isArray(classes) ? classes : [classes]));
};
