import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import { useClipboard } from 'use-clipboard-copy';

import { CopyText } from './components/CopyText';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { COPIED_TIMEOUT, Locale } from '../../../../common';
import DynamicTooltip from '../DynamicTooltip/DynamicTooltip';
import { TooltipPosition } from '../SimpleTooltip/SimpleTooltip';

export interface CopyTooltipProps {
  /**
   * The text to copy into the clipboard
   */
  textToCopy: string;

  /**
   * The title of the tooltip
   */
  title?: string;

  /**
   * The description of the tooltip
   */
  description?: string;

  /**
   * The position of the tooltip
   */
  position?: TooltipPosition;

  /**
   * The children of the component
   */
  children: ReactNode;

  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * The time in milliseconds to display the copied text
   */
  copiedTimeout?: number;

  /**
   * The className of the content
   */
  className?: string;
}

/**
 * This component is a wrapper of the DynamicTooltip component, it allows to create a tooltip with a copy button
 * @author Carlos Cuatin <carlos.cuatin@edenmed.com>
 * Created at 2024-01-23
 */
export const CopyTooltip = ({
  title,
  locale,
  children,
  className,
  textToCopy,
  description,
  copiedTimeout = COPIED_TIMEOUT,
  position = TooltipPosition.RIGHT,
}: CopyTooltipProps) => {
  const [open, setOpen] = useState(false);
  const clipboard = useClipboard({ copiedTimeout });
  const handleOnClick = () => clipboard.copy(textToCopy);

  const classes = {
    container: cn(className, 'e-cursor-pointer'),
    title: cn({ 'e-mb-1': description }),
  };

  const renderContentTooltip = () => {
    return (
      <>
        {title && <BaseTooltip.Title className={classes.title}>{title}</BaseTooltip.Title>}
        {description && <BaseTooltip.Description>{description}</BaseTooltip.Description>}
        {(title || description) && <div className="e-h-px e-bg-neutral-500 e-my-1" />}
        <CopyText locale={locale} copied={clipboard.copied} />
      </>
    );
  };

  return (
    <DynamicTooltip
      open={open}
      controlled={true}
      preventClose={true}
      position={position}
      content={renderContentTooltip()}>
      <span
        onClick={handleOnClick}
        className={classes.container}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}>
        {children}
      </span>
    </DynamicTooltip>
  );
};
