import React from 'react';
import cn from 'classnames';

import { Locale, Translations } from '../../../../../common';
import { Icon, IconCatalog } from '../../../../Icon/Icon';

const translations: Translations = {
  en: {
    copy: 'Click to copy',
    copied: 'Copied text',
  },
  es: {
    copy: 'Clic para copiar',
    copied: 'Texto copiado',
  },
  pt: {
    copy: 'Clique para copiar',
    copied: 'Texto copiado',
  },
};

export interface CopyTextProps {
  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * variable to know if the text was copied
   */
  copied?: boolean;
}

/**
 * This component is a wrapper of the DynamicTooltip component, it allows to create a tooltip with a copy button
 * @author Carlos Cuatin <carlos.cuatin@edenmed.com>
 * Created at 2024-01-23
 */
export const CopyText = ({ locale, copied }: CopyTextProps) => {
  const copyTranslation = translations[locale].copy;
  const copiedTranslation = translations[locale].copied;
  const copyText = copied ? copiedTranslation : copyTranslation;
  const copyIcon = copied ? IconCatalog.checkRounded : IconCatalog.copy;

  const classes = {
    text: cn('e-text-2xs e-font-medium', {
      'e-text-success-300': copied,
      'e-text-neutral-300': !copied,
    }),
    icon: cn({
      'e-text-success-300': copied,
      'e-text-neutral-300': !copied,
    }),
  };

  return (
    <div className="e-flex e-items-center e-space-x-0.5">
      <span className={classes.text}>{copyText}</span>
      <Icon icon={copyIcon} width={12} height={12} className={classes.icon} />
    </div>
  );
};
