import { SelectOption } from './input';

export interface MultiSelectorOutput {
  parent: SelectOption;
  options: Array<string>;
}

export enum ParentCheckStatus {
  // All children are selected
  everyChild = 'everyChild',
  // Some children are selected
  someChild = 'someChild',
  // No children are selected
  noneChild = 'noneChild',
  // Parent has no children
  hasNoChild = 'hasNoChild',
}

/**
 * @deprecated Use MultiSelectorOutput instead
 */
export interface Output extends MultiSelectorOutput {}
