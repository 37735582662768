import React from 'react';
import cn from 'classnames';
import { Arrow as RadixArrow, MenuArrowProps } from '@radix-ui/react-dropdown-menu';

export interface ArrowProps extends MenuArrowProps {}

export const Arrow = React.forwardRef<SVGSVGElement, ArrowProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(' e-fill-neutral-600', className),
  };
  return <RadixArrow className={classes.container} ref={forwardedRef} {...rest} />;
});
