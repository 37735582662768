import React from 'react';
import { Provider, Root, Trigger, Portal, TooltipProps, Arrow, Tooltip as RadixTooltip } from '@radix-ui/react-tooltip';
import { Content, Description, Title } from './components';

export const BaseTooltip = (props: TooltipProps) => <RadixTooltip {...props} />;
BaseTooltip.Provider = Provider;
BaseTooltip.Root = Root;
BaseTooltip.Title = Title;
BaseTooltip.Trigger = Trigger;
BaseTooltip.Portal = Portal;
BaseTooltip.Content = Content;
BaseTooltip.Description = Description;
BaseTooltip.Arrow = Arrow;
