import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const appendChild = (containerEl: HTMLDivElement): HTMLDivElement => document.body.appendChild(containerEl);
const removeChild = (containerEl: HTMLDivElement): HTMLDivElement => document.body.removeChild(containerEl);

export interface PortalProps {
  /**
   * The content displayed inside the portal.
   */
  children: JSX.Element;

  /**
   * Function to execute when the component is unmounted
   */
  onUnmount?: () => void;
}

/**
 * Portal component
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-05-25
 */
const Portal: React.FC<PortalProps> = ({ children, onUnmount }) => {
  const [containerEl] = useState(document.createElement('div'));

  useEffect(() => {
    appendChild(containerEl);

    return (): void => {
      removeChild(containerEl);
      if (onUnmount) onUnmount();
    };
  }, [containerEl]);

  return ReactDOM.createPortal(children, containerEl);
};

export default Portal;
