import type { Translations } from '../types';

export enum Locale {
  en = 'en',
  es = 'es',
  pt = 'pt',
}

export const LocaleLabel: Translations = {
  [Locale.en]: {
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese',
  },
  [Locale.es]: {
    en: 'Inglés',
    es: 'Español',
    pt: 'Portugués',
  },
  [Locale.pt]: {
    en: 'Inglês',
    es: 'Espanhol',
    pt: 'Português',
  },
};
