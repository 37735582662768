import React from 'react';
import { CompanyAndProductLogoSize } from './CompanyAndProductLogoSize';

export interface ManagementProps {
  /**
   * Specify the size of the logo.
   * @default md
   */
  size?: CompanyAndProductLogoSize;
}

const ManagementSizes: Record<CompanyAndProductLogoSize, { width: number; height: number }> = {
  [CompanyAndProductLogoSize.md]: {
    width: 277,
    height: 34,
  },
  [CompanyAndProductLogoSize.sm]: {
    width: 167,
    height: 20,
  },
};

/**
 * Management component for the management product.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-07-17
 */
export const Management = ({ size = CompanyAndProductLogoSize.md }: ManagementProps) => {
  return (
    <svg
      fill="currentColor"
      width={ManagementSizes[size].width}
      height={ManagementSizes[size].height}
      viewBox="0 0 277 34"
      preserveAspectRatio="xMinYMin meet"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7181 25.7035C24.4916 25.7035 22.6192 24.8723 21.1027 23.2111C19.5851 21.5505 18.8271 19.539 18.8271 17.1784C18.8271 14.8182 19.5851 12.8073 21.1027 11.1456C22.6192 9.48491 24.4916 8.65376 26.7181 8.65376C27.8313 8.65376 28.8573 8.90021 29.7959 9.39147C30.734 9.88327 31.4543 10.4794 31.9569 11.1789V0.784912H35.5586V25.3751H31.9569V23.1784C31.4543 23.8784 30.734 24.474 29.7959 24.9652C28.8573 25.457 27.8313 25.7035 26.7181 25.7035ZM23.8689 20.9161C24.7863 21.9221 25.9541 22.4248 27.3729 22.4248C28.7918 22.4248 29.9591 21.9221 30.8764 20.9161C31.7932 19.9111 32.2516 18.6652 32.2516 17.1784C32.2516 15.6926 31.7932 14.4467 30.8764 13.4406C29.9591 12.4363 28.7918 11.9325 27.3729 11.9325C25.9541 11.9325 24.7863 12.4363 23.8689 13.4406C22.9527 14.4467 22.4943 15.6926 22.4943 17.1784C22.4943 18.6652 22.9527 19.9111 23.8689 20.9161Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54586 25.7035C6.05741 25.7035 4.01099 24.9051 2.40659 23.3095C0.802197 21.7144 0 19.6702 0 17.1784C0 14.7521 0.807108 12.7253 2.42296 11.0969C4.03773 9.46852 6.07869 8.65376 8.54586 8.65376C10.816 8.65376 12.6982 9.37507 14.194 10.8182C15.6892 12.2603 16.4369 14.2494 16.4369 16.7855C16.4369 17.2226 16.4254 17.6161 16.4041 17.9658H3.53622C3.62298 19.2986 4.1365 20.3756 5.07512 21.1953C6.0132 22.015 7.18157 22.4248 8.5786 22.4248C10.6741 22.4248 12.2349 21.5937 13.2608 19.9325L16.0112 21.8997C14.4395 24.4357 11.9511 25.7035 8.54586 25.7035ZM3.69993 15.3423H12.8024C12.606 14.2062 12.0984 13.3155 11.2799 12.6702C10.4613 12.0264 9.50631 11.7029 8.41488 11.7029C7.2798 11.7029 6.27023 12.0204 5.38618 12.6538C4.50213 13.2882 3.94004 14.1844 3.69993 15.3423Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2886 23.3909C42.893 24.9866 44.9394 25.7849 47.4279 25.7849C50.8331 25.7849 53.3216 24.5172 54.8932 21.9811L52.1428 20.0139C51.1169 21.6751 49.5561 22.5062 47.4606 22.5062C46.0636 22.5062 44.8952 22.0964 43.9571 21.2767C43.0185 20.457 42.505 19.38 42.4182 18.0472H55.2861C55.3074 17.6975 55.3189 17.304 55.3189 16.8669C55.3189 14.3308 54.5712 12.3417 53.076 10.8997C51.5802 9.4565 49.698 8.73519 47.4279 8.73519C44.9607 8.73519 42.9197 9.54994 41.305 11.1784C39.6891 12.8068 38.882 14.8335 38.882 17.2598C38.882 19.7516 39.6842 21.7958 41.2886 23.3909ZM51.6844 15.4237H42.5819C42.822 14.2658 43.3841 13.3696 44.2682 12.7352C45.1522 12.1019 46.1618 11.7844 47.2969 11.7844C48.3883 11.7844 49.3433 12.1079 50.1619 12.7516C50.9804 13.3969 51.488 14.2876 51.6844 15.4237Z"
      />
      <path d="M58.1184 8.98164H61.7201V11.2111C62.7455 9.50622 64.4044 8.65322 66.697 8.65322C68.5955 8.65322 70.1126 9.27672 71.2482 10.5221C72.3827 11.7691 72.9508 13.4297 72.9508 15.5062V25.3751H69.3486V16.0969C69.3486 14.7849 69.0424 13.7636 68.4318 13.0308C67.8206 12.2986 66.9916 11.9319 65.9439 11.9319C64.6997 11.9319 63.6841 12.4029 62.8988 13.3417C62.1124 14.2822 61.7201 15.6046 61.7201 17.3095V25.3751H58.1184V8.98164Z" />
      <path d="M269.285 4.15308H271.547V8.70649H276.264V10.8685H271.547V20.3357C271.547 21.3403 271.768 22.0555 272.209 22.4813C272.651 22.9072 273.324 23.1201 274.229 23.1201C275.047 23.1201 275.726 23 276.264 22.7598V24.8236C275.554 25.1075 274.757 25.2494 273.873 25.2494C272.516 25.2494 271.412 24.8509 270.562 24.0537C269.711 23.2566 269.285 22.0828 269.285 20.5322V10.8685H265.828V8.70649H269.285V4.15308Z" />
      <path d="M98.6247 8.37894C97.3323 8.37894 96.2176 8.71196 95.2805 9.37805C94.3435 10.0441 93.6704 10.945 93.2611 12.0806C92.8734 10.9232 92.2433 10.0169 91.3709 9.36169C90.4985 8.70652 89.4592 8.37894 88.2529 8.37894C87.1328 8.37894 86.1312 8.65193 85.248 9.1979C84.3649 9.74387 83.6863 10.4973 83.2124 11.4582V8.70651H80.9507V25.0857H83.2124V16.9288C83.2124 14.9852 83.6594 13.4292 84.5533 12.2608C85.4473 11.0924 86.6051 10.5082 88.0268 10.5082C89.1038 10.5082 89.9385 10.9341 90.5309 11.7858C91.1232 12.6375 91.4194 13.7622 91.4194 15.1599V25.0857H93.6812V16.9288C93.6812 14.9852 94.1174 13.4292 94.9897 12.2608C95.8621 11.0924 97.0199 10.5082 98.4632 10.5082C99.5617 10.5082 100.407 10.9341 101 11.7858C101.592 12.6375 101.888 13.7622 101.888 15.1599V25.0857H104.15V14.8323C104.15 12.9105 103.649 11.3545 102.647 10.1642C101.646 8.97403 100.305 8.37894 98.6247 8.37894Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.469 11.4582C120.887 10.541 120.052 9.79847 118.965 9.23066C117.877 8.66285 116.719 8.37894 115.491 8.37894C113.229 8.37894 111.323 9.20882 109.772 10.8686C108.221 12.5283 107.446 14.5375 107.446 16.8961C107.446 19.2547 108.221 21.2638 109.772 22.9236C111.323 24.5833 113.229 25.4132 115.491 25.4132C116.719 25.4132 117.877 25.1293 118.965 24.5615C120.052 23.9937 120.887 23.2512 121.469 22.334V25.0856H123.73V8.70651H121.469V11.4582ZM119.998 21.4331C118.889 22.667 117.462 23.284 115.717 23.284C113.972 23.284 112.545 22.667 111.436 21.4331C110.327 20.1992 109.772 18.6869 109.772 16.8961C109.772 15.1053 110.327 13.593 111.436 12.3591C112.545 11.1252 113.972 10.5082 115.717 10.5082C117.462 10.5082 118.889 11.1252 119.998 12.3591C121.108 13.593 121.662 15.1053 121.662 16.8961C121.662 18.6869 121.108 20.1992 119.998 21.4331Z"
      />
      <path d="M130.898 11.4582C131.996 9.40535 133.763 8.37894 136.197 8.37894C137.985 8.37894 139.444 8.95767 140.575 10.1151C141.706 11.2726 142.271 12.845 142.271 14.8323V25.0857H140.009V15.1599C140.009 13.7404 139.638 12.6102 138.895 11.7694C138.152 10.9286 137.166 10.5082 135.938 10.5082C134.473 10.5082 133.267 11.0815 132.319 12.228C131.372 13.3746 130.898 14.9415 130.898 16.9288V25.0857H128.636V8.70651H130.898V11.4582Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.595 11.4582C159.014 10.541 158.179 9.79847 157.091 9.23066C156.003 8.66285 154.846 8.37894 153.618 8.37894C151.356 8.37894 149.45 9.20882 147.899 10.8686C146.348 12.5283 145.572 14.5375 145.572 16.8961C145.572 19.2547 146.348 21.2638 147.899 22.9236C149.45 24.5833 151.356 25.4132 153.618 25.4132C154.846 25.4132 156.003 25.1293 157.091 24.5615C158.179 23.9937 159.014 23.2512 159.595 22.334V25.0856H161.857V8.70651H159.595V11.4582ZM158.125 21.4331C157.016 22.667 155.589 23.284 153.844 23.284C152.099 23.284 150.672 22.667 149.563 21.4331C148.453 20.1992 147.899 18.6869 147.899 16.8961C147.899 15.1053 148.453 13.593 149.563 12.3591C150.672 11.1252 152.099 10.5082 153.844 10.5082C155.589 10.5082 157.016 11.1252 158.125 12.3591C159.234 13.593 159.789 15.1053 159.789 16.8961C159.789 18.6869 159.234 20.1992 158.125 21.4331Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.86 10.8358C188.433 9.1979 190.436 8.37894 192.87 8.37894C195.046 8.37894 196.855 9.12144 198.298 10.6065C199.742 12.0915 200.463 14.1116 200.463 16.6668V17.2892H186.828C186.828 18.9926 187.388 20.4176 188.508 21.5641C189.628 22.7107 191.061 23.284 192.805 23.284C194.098 23.284 195.159 23.0273 195.988 22.5141C196.817 22.0009 197.534 21.2311 198.137 20.2047L199.978 21.4167C198.449 24.0811 196.058 25.4132 192.805 25.4132C190.328 25.4132 188.325 24.5997 186.796 22.9727C185.266 21.3458 184.502 19.3202 184.502 16.8961C184.502 14.4938 185.288 12.4737 186.86 10.8358ZM189.041 11.8022C187.953 12.6648 187.28 13.8168 187.022 15.2582H198.104C197.889 13.795 197.297 12.6375 196.327 11.7858C195.358 10.9341 194.173 10.5082 192.773 10.5082C191.373 10.5082 190.129 10.9395 189.041 11.8022Z"
      />
      <path d="M221.013 8.37894C219.72 8.37894 218.606 8.71196 217.669 9.37805C216.732 10.0441 216.059 10.945 215.649 12.0806C215.262 10.9232 214.631 10.0169 213.759 9.36169C212.887 8.70652 211.847 8.37894 210.641 8.37894C209.521 8.37894 208.519 8.65193 207.636 9.1979C206.753 9.74387 206.074 10.4973 205.601 11.4582V8.70651H203.339V25.0857H205.601V16.9288C205.601 14.9852 206.048 13.4292 206.941 12.2608C207.835 11.0924 208.993 10.5082 210.415 10.5082C211.492 10.5082 212.327 10.9341 212.919 11.7858C213.511 12.6375 213.808 13.7622 213.808 15.1599V25.0857H216.069V16.9288C216.069 14.9852 216.505 13.4292 217.378 12.2608C218.25 11.0924 219.408 10.5082 220.851 10.5082C221.95 10.5082 222.795 10.9341 223.388 11.7858C223.98 12.6375 224.276 13.7622 224.276 15.1599V25.0857H226.538V14.8323C226.538 12.9105 226.037 11.3545 225.036 10.1642C224.034 8.97403 222.693 8.37894 221.013 8.37894Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.225 10.8358C233.797 9.1979 235.8 8.37894 238.234 8.37894C240.41 8.37894 242.219 9.12144 243.663 10.6065C245.106 12.0915 245.827 14.1116 245.827 16.6668V17.2892H232.192C232.192 18.9926 232.752 20.4176 233.872 21.5641C234.993 22.7107 236.425 23.284 238.17 23.284C239.462 23.284 240.523 23.0273 241.352 22.5141C242.182 22.0009 242.898 21.2311 243.501 20.2047L245.343 21.4167C243.813 24.0811 241.422 25.4132 238.17 25.4132C235.693 25.4132 233.689 24.5997 232.16 22.9727C230.631 21.3458 229.866 19.3202 229.866 16.8961C229.866 14.4938 230.652 12.4737 232.225 10.8358ZM234.406 11.8022C233.318 12.6648 232.645 13.8168 232.386 15.2582H243.469C243.253 13.795 242.661 12.6375 241.692 11.7858C240.722 10.9341 239.538 10.5082 238.137 10.5082C236.737 10.5082 235.493 10.9395 234.406 11.8022Z"
      />
      <path d="M256.91 8.37894C254.476 8.37894 252.71 9.40535 251.611 11.4582V8.70651H249.349V25.0857H251.611V16.9288C251.611 14.9415 252.085 13.3746 253.033 12.228C253.981 11.0815 255.187 10.5082 256.652 10.5082C257.879 10.5082 258.865 10.9286 259.608 11.7694C260.351 12.6102 260.723 13.7404 260.723 15.1599V25.0857H262.985V14.8323C262.985 12.845 262.419 11.2726 261.288 10.1151C260.157 8.95767 258.698 8.37894 256.91 8.37894Z" />
      <path d="M179.989 26.6853C179.989 27.7773 179.116 28.6618 178.039 28.6618H169.951C167.689 28.6618 165.847 30.529 165.847 32.8221V33.2152H168.001V32.8221C168.001 31.7301 168.874 30.8456 169.951 30.8456H178.039C180.301 30.8456 182.143 28.9784 182.143 26.6853V26.1284H179.989V26.6853Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.935 8.59732H182.197V15.7059C182.245 16.0935 182.277 16.4921 182.277 16.9016C182.277 19.282 181.48 21.2966 179.886 22.9455C178.292 24.5943 176.278 25.4187 173.844 25.4187C171.41 25.4187 169.396 24.5943 167.802 22.9455C166.208 21.2966 165.411 19.282 165.411 16.9016C165.411 14.5211 166.208 12.5065 167.802 10.8576C169.396 9.20881 171.41 8.38438 173.844 8.38438C176.278 8.38438 178.292 9.20881 179.886 10.8576C179.895 10.8658 179.903 10.8754 179.911 10.8849C179.919 10.8945 179.927 10.904 179.935 10.9122V8.59732ZM173.844 23.284C175.654 23.284 177.124 22.667 178.255 21.4331C179.299 20.292 179.854 18.9052 179.935 17.2892V16.5084C179.859 14.8869 179.299 13.5001 178.255 12.3591C177.124 11.1252 175.654 10.5082 173.844 10.5082C172.035 10.5082 170.565 11.1252 169.434 12.3591C168.303 13.593 167.738 15.1053 167.738 16.8961C167.738 18.6869 168.303 20.1992 169.434 21.4331C170.565 22.667 172.035 23.284 173.844 23.284Z"
      />
    </svg>
  );
};
