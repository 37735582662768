import React, { ChangeEvent, FocusEvent, InputHTMLAttributes, useState } from 'react';
import cn from 'classnames';
import { FormFieldState } from '../../../common/constants';
import { Input } from '../../../common/types/input';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../Loaders';

export interface TextInputProps extends Input, Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> {
  /**
   * The icon to display on the left side.
   */
  startIcon?: IconCatalog;

  /**
   * The icon to display on the right side.
   */
  endIcon?: IconCatalog;

  /**
   * The callback to get notified when the end icon was clicked.
   */
  onEndIconClick?: () => void;
}

/**
 * A Text Input enable the user to interact with and input short content and data.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created at 2022-05-24
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      isDisabled = false,
      isFullWidth = false,
      isReadOnly = false,
      isRounded = false,
      isFlat = false,
      isLoading = false,
      isRequired = false,
      fieldState = FormFieldState.default,
      label,
      assistiveText,
      borderless = false,
      autoComplete = 'off',
      className,
      dataTestId,
      startIcon,
      endIcon,
      value = '',
      name,
      onChange,
      onBlur,
      onFocus,
      onEndIconClick,
      disabled,
      ...restOfProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isInputDisabled = isDisabled || disabled;
    const isInputActive = fieldState === FormFieldState.active && !isDisabled;

    const classes = {
      container: cn(className, { 'e-w-full': isFullWidth }),
      inputContainer: cn('e-relative e-flex e-items-center e-overflow-hidden e-space-x-2', 'e-h-10', {
        'e-w-full': isFullWidth,
        'e-rounded-lg e-p-2': !isRounded,
        'e-rounded-full e-px-4 e-py-2': isRounded,
        'e-border-neutral-400': fieldState === FormFieldState.default && !isInputDisabled,
        'e-border-error-500': fieldState === FormFieldState.error && !isInputDisabled,
        'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isInputActive,
        'e-border e-bg-neutral-700': !borderless && !isInputDisabled,
        'e-border-0 e-bg-neutral-700': isFlat,
        'e-text-neutral-50': !isInputDisabled,
        'e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default': isInputDisabled,
      }),
      input: cn(
        'e-w-full',
        'e-transition e-duration-100 e-ease-out e-outline-none e-bg-transparent',
        'placeholder:e-text-neutral-300 e-text-base e-font-light',
      ),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
      icon: cn('e-flex e-items-center e-justify-center', {
        'e-cursor-default': isInputDisabled,
      }),
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) onChange(event);
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    const handleEndIconClick = (): void => {
      if (onEndIconClick) onEndIconClick();
    };

    const endContent = () => {
      if (isLoading) return <Spinner className={classes.icon} size={SpinnerSize.xs} variant={SpinnerVariant.neutral} />;

      if (endIcon) {
        if (onEndIconClick)
          return (
            <i role="button" tabIndex={0} onKeyDown={handleEndIconClick} onClick={handleEndIconClick}>
              <Icon className={classes.icon} icon={endIcon} />
            </i>
          );
        return <Icon className={classes.icon} icon={endIcon} />;
      }
    };

    /* Render JSX */
    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <div className={classes.inputContainer}>
          {startIcon && <Icon className={classes.icon} icon={startIcon} />}
          <input
            id={id}
            data-testid={dataTestId}
            ref={ref}
            name={name}
            className={classes.input}
            disabled={isInputDisabled}
            value={value}
            readOnly={isReadOnly}
            required={isRequired}
            autoComplete={autoComplete}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            {...restOfProps}
          />
          {endContent()}
        </div>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
