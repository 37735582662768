import React, { HTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

export enum ScrollableDirection {
  Horizontal,
  Vertical,
  Both,
}

export interface ScrollableProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;
  /**
   * Component children to display as the main content
   */
  children: React.ReactNode;
  /**
   * Specify an optional direction the scroll should apply, default: `ScrollableDirection.Both`
   */
  direction?: ScrollableDirection;
}

/**
 * `Scrollable` shows an horizontal/vertical scrollbar if the content
 * goes beyond its boundaries.
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2022-06-09
 */
export const Scrollable = forwardRef<HTMLDivElement, ScrollableProps>(
  ({ className, children, direction = ScrollableDirection.Both, ...props }, forwardedRef) => {
    const classes = {
      container: cn(
        className,
        ' e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg',
        {
          'e-horizontal-scroll e-overflow-y-hidden e-overflow-x-auto': direction === ScrollableDirection.Horizontal,
          'e-scrollbar-w-2 e-overflow-y-auto e-overflow-x-hidden': direction === ScrollableDirection.Vertical,
          'e-scrollbar-w-2 e-horizontal-scroll e-overflow-auto': direction === ScrollableDirection.Both,
        },
      ),
    };
    return (
      <div ref={forwardedRef} className={classes.container} {...props}>
        {children}
      </div>
    );
  },
);
