import React from 'react';
import cn from 'classnames';
import { AutoTooltip, TooltipPosition } from '../../../Overlays/Tooltips';
import { TagV2, TagV2Type } from '../../../TagV2/TagV2';
import { Locale, Translations } from '../../../../common';
import { IconCatalog } from '../../../Icon/Icon';

const translations: Translations = {
  en: {
    newStudy: 'new study assigned',
    newStudies: 'new studies assigned',
    urgent: 'Urgent',
    urgents: 'Urgents',
  },
  es: {
    newStudy: 'nuevo estudio asignado',
    newStudies: 'nuevos estudios asignados',
    urgent: 'Urgente',
    urgents: 'Urgentes',
  },
  pt: {
    newStudy: 'novo estudo atribuído',
    newStudies: 'novos estudos atribuídos',
    urgent: 'Urgente',
    urgents: 'Urgentes',
  },
};

export enum AssistiveTextType {
  neutral = 'neutral',
  error = 'error',
}

export interface AccountItemProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * Set the user's full name
   */
  userFullname: string;

  /**
   * Set the laboratory name
   */
  laboratoryName: string;

  /**
   * Set the user's email
   */
  userEmail?: string;

  /**
   * Set the number of new studies
   */
  newStudyCount?: number;

  /**
   * Set the number of urgent studies
   */
  urgentStudyCount?: number;

  /**
   * Provide a handler that is called when the item was clicked
   */
  onClick?: () => void;

  /**
   * Flag to indicate if current item is active.
   */
  isActive?: boolean;
}

/**
 * AccountItem (AccountPopupMenu)
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-08-04
 */
export const AccountItem = ({
  className,
  locale = Locale.es,
  userFullname,
  laboratoryName,
  userEmail,
  urgentStudyCount,
  newStudyCount,
  isActive,
  onClick,
}: AccountItemProps) => {
  const classes = {
    option: cn(className, 'e-flex e-flex-col e-w-full e-overflow-hidden e-gap-1'),
    assistiveText: cn('e-flex e-text-xs e-font-regular', {
      'e-text-error-500': newStudyCount,
    }),
  };

  const studyText = newStudyCount
    ? newStudyCount > 1
      ? translations[locale].newStudies
      : translations[locale].newStudy
    : '';

  const urgentStudyText = urgentStudyCount
    ? urgentStudyCount > 1
      ? translations[locale].urgents
      : translations[locale].urgent
    : '';

  const handleClick = () => {
    if (onClick) onClick();
  };

  const renderNewStudyCount = () => {
    if (isActive) return <span className="e-list-item e-ml-4 e-mr-1">{newStudyCount}</span>;
    return newStudyCount;
  };

  const renderNotificationInfo = () => {
    return (
      <>
        {newStudyCount && (
          <div className={classes.assistiveText}>
            {renderNewStudyCount()} {studyText}
          </div>
        )}
        {urgentStudyCount && (
          <TagV2 startIcon={IconCatalog.warning} type={TagV2Type.error}>
            {urgentStudyCount} {urgentStudyText}
          </TagV2>
        )}
      </>
    );
  };

  /* Render JSX */
  return (
    <div
      role="option"
      aria-selected="false"
      tabIndex={-1}
      className={classes.option}
      onClick={handleClick}
      onKeyDown={handleClick}>
      <div className="e-flex e-w-full e-text-xs e-text-neutral-200 e-font-medium">{userFullname}</div>
      <AutoTooltip
        title={userFullname}
        description={laboratoryName}
        position={TooltipPosition.RIGHT}
        triggerClassName="e-text-neutral-50 e-font-medium">
        {laboratoryName}
      </AutoTooltip>
      <div className="e-flex e-items-center e-w-full e-justify-between">
        {userEmail && isActive && (
          <div className="e-flex e-flex-col e-w-full">
            <div className="e-flex e-text-xs e-font-regular e-text-neutral-200">{userEmail}</div>
            <div className="e-flex e-items-center e-justify-between">{renderNotificationInfo()}</div>
          </div>
        )}
        {!userEmail && isActive && (
          <div className="e-flex e-items-center e-w-full e-justify-between">{renderNotificationInfo()}</div>
        )}
        {!isActive && renderNotificationInfo()}
      </div>
    </div>
  );
};
