import React, { useMemo } from 'react';
import cn from 'classnames';

const TOTAL_CIRCUMFERENCE_VALUE = 100;

export enum ProgressLoaderSize {
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export type ProgressLoaderSizeOptions = {
  /**
   * Size of the loader
   */
  size: number;
  /**
   * Width of the track
   */
  trackWidth: number;
  /**
   * Width of the colored indicator
   */
  indicatorWidth: number;
};

interface ProgressLoaderProps {
  /**
   * Size of the loader
   */
  size?: ProgressLoaderSize;
  /**
   * Value of the progress
   */
  value?: number;
}

const Sizes: Record<ProgressLoaderSize, ProgressLoaderSizeOptions> = {
  [ProgressLoaderSize.xxl]: {
    size: 96,
    trackWidth: 10,
    indicatorWidth: 10,
  },
  [ProgressLoaderSize.xl]: {
    size: 64,
    trackWidth: 8,
    indicatorWidth: 8,
  },
  [ProgressLoaderSize.lg]: {
    size: 48,
    trackWidth: 6,
    indicatorWidth: 6,
  },
};

const LabelSizes: Record<ProgressLoaderSize, string> = {
  [ProgressLoaderSize.xxl]: 'e-text-base',
  [ProgressLoaderSize.xl]: 'e-text-sm',
  [ProgressLoaderSize.lg]: 'e-text-2xs',
};

/**
 * A ProgressLoader is a circular progress indicator that displays the percentage of a task that has been completed.
 * @author Javier Diaz<javier.diaz@evacenter.com>
 * Created on 2023-03-08
 */
export const ProgressLoader = ({ size = ProgressLoaderSize.lg, value = 0 }: ProgressLoaderProps) => {
  if (value < 0 || value > 100) throw new Error('Progress value must be between 0 and 100');
  const { size: defaultSize, trackWidth, indicatorWidth } = Sizes[size];

  const classes = {
    label: cn(
      'e-absolute e-top-1/2 e-left-1/2 -e-translate-x-1/2 -e-translate-y-1/2 e-font-medium e-text-base-white e-text-center',
      LabelSizes[size],
    ),
  };

  const options = useMemo(() => {
    const center = defaultSize / 2;
    const radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
    const dashArray = 2 * Math.PI * radius; // Calculate the circumference of a circle with the given radius
    const dashOffset = dashArray * ((TOTAL_CIRCUMFERENCE_VALUE - value) / TOTAL_CIRCUMFERENCE_VALUE); // Calculate the offset of the dash array

    return {
      center,
      radius,
      dashArray,
      dashOffset,
    };
  }, [size, value]);

  return (
    <div className="e-relative" style={{ width: defaultSize, height: defaultSize }}>
      <svg className="-e-rotate-90" style={{ width: defaultSize, height: defaultSize }}>
        <circle
          className="e-text-neutral-500"
          cx={options.center}
          cy={options.center}
          fill="transparent"
          r={options.radius}
          stroke="currentColor"
          strokeWidth={trackWidth}
        />
        <circle
          className="e-text-primary-500"
          cx={options.center}
          cy={options.center}
          fill="transparent"
          r={options.radius}
          stroke="currentColor"
          strokeWidth={indicatorWidth}
          strokeDasharray={options.dashArray}
          strokeDashoffset={options.dashOffset}
        />
      </svg>
      <span className={classes.label}>{value}%</span>
    </div>
  );
};
