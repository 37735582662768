import { useEffect, useState } from 'react';

/**
 * This hook return if the text inside the element is clamped or not
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * @author Alejandro Forero<alejandro.forero@evacenter.com>
 * Created at 2023-09-07
 */
interface UseIsTextClamped {
  (
    /*
     * Reference to the content element that will be observed if it overflows the container element.
     */
    contentRef: React.RefObject<HTMLDivElement>,
  ): boolean;
}

export const useIsTextClamped: UseIsTextClamped = (contentRef) => {
  const [isTextClamped, setIsTextClamped] = useState(false);

  useEffect(() => {
    if (!contentRef.current) return;
    const observer = new ResizeObserver((entries) => {
      const currentElement = entries[0].target;
      setIsTextClamped(currentElement.scrollHeight > currentElement.clientHeight);
    });
    observer.observe(contentRef.current);

    return () => observer.disconnect();
  }, [contentRef]);

  return isTextClamped;
};
