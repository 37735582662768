import cl, { Argument } from 'classnames';
import { extendTailwindMerge } from 'tailwind-merge';

/**
 * Combines and merges Tailwind CSS classes using twMerge and classnames utility functions.
 * twMerge is used to handle conflicts between classes effectively.
 *
 * @param {...Argument} inputs - An array of class values to be combined and merged.
 * @returns {string} - The merged and combined class names as a string.
 */
export const twMerge = extendTailwindMerge({
  prefix: 'e-',
});
export const cn = (...inputs: Array<Argument>) => {
  return twMerge(cl(inputs));
};

/**
 * Source:
 * Tailwind merge: https://github.com/dcastil/tailwind-merge
 */
