import React from 'react';
import cn from 'classnames';
import { useToast } from '../../../common/contexts';
import { Toast } from './Toast';

export enum ToastPlacement {
  top = 'top',
  bottom = 'bottom',
}

const Placement: Record<ToastPlacement, string> = {
  [ToastPlacement.top]: 'e-top-3 e-left-1/2 -e-translate-x-1/2 e-transform',
  [ToastPlacement.bottom]: 'e-bottom-3 e-left-1/2 -e-translate-x-1/2 e-transform',
};

export interface ToastContainerProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The position (relative to the target) at which the toast should appear.
   */
  placement?: ToastPlacement;
}

/**
 * The main container for all the Toasts in the app.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-08-04
 * @deprecated
 */
export const ToastContainer = ({ className, placement = ToastPlacement.top }: ToastContainerProps) => {
  const classes = cn(className, 'e-fixed e-z-40 e-min-w-128 e-space-y-4', Placement[placement]);
  const { currentToasts } = useToast();

  if (currentToasts.length === 0) return null;

  const wrapperToast = currentToasts.map((toast) => (
    <div key={toast.id} className="e-flex e-w-full e-transform e-transition-all e-duration-300">
      <Toast {...toast} />
    </div>
  ));

  /* RENDER */
  return <div className={classes}>{wrapperToast}</div>;
};
