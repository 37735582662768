import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonVariant } from '../../Buttons/Button/Button';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Scrollable } from '../../Scrollable/Scrollable';
import { Divider } from '../../Divider/Divider';
import { getMaxHeightByItems, Locale, Translations } from '../../../common';
import { AccountItem } from './AccountItem/AccountItem';
import { TextInput } from '../../Inputs';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';

const translations: Translations = {
  en: {
    noResults: 'No results to display',
    otherAccounts: 'Other accounts',
    searchPlaceholder: 'Type to filter',
    logOut: 'Log out',
  },
  es: {
    noResults: 'No hay resultados que mostrar',
    otherAccounts: 'Otras cuentas',
    searchPlaceholder: 'Escribe para filtrar',
    logOut: 'Cerrar sesión',
  },
  pt: {
    noResults: 'Não há resultados para mostrar',
    otherAccounts: 'Outras contas',
    searchPlaceholder: 'Digite para filtrar',
    logOut: 'Sair',
  },
};

export type AccountOption = {
  id: string;
  userFullname: string;
  laboratoryName: string;
  userEmail?: string;
  newStudyCount?: number;
  urgentStudyCount?: number;
  isActive: boolean;
};

export interface AccountPopupMenuProps {
  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The locale to be used by the component
   */
  locale?: Locale;

  /**
   * The list of accounts to display
   */
  accounts: Array<AccountOption>;

  /**
   * The handler that is called when the user clicks on an option
   */
  onOptionClick?: (account: AccountOption) => void;

  /**
   * The handler that is called when the user changes the language
   */
  onLanguageChange: (locale: Locale) => void;

  /**
   * The handler that is called when the user clicks on the logout button
   */
  onLogoutClick?: () => void;
}

/**
 * This components display a popup menu to list user accounts.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-08-04
 */
export const AccountPopupMenu = ({
  className,
  dataTestId,
  locale = Locale.es,
  accounts = [],
  onOptionClick,
  onLogoutClick,
  onLanguageChange,
}: AccountPopupMenuProps) => {
  const classes = {
    container: cn(className, 'e-bg-neutral-700 e-rounded-lg e-p-4'),
    list: cn('e-flex-grow e-w-full'),
  };

  const LIST_LENGTH = accounts.length - 1;
  const MAX_ITEMS_TO_DISPLAY = 3;
  const MIN_ITEMS_TO_DISPLAY = 1;

  const [query, setQuery] = useState('');
  const getMatchingQueryOptions = (option: AccountOption) =>
    option.laboratoryName.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));

  const filteredAccounts = useMemo(() => {
    if (query === '') return accounts;
    return accounts.filter(getMatchingQueryOptions);
  }, [accounts, query]);

  const currentAccount = accounts.find((account) => account.isActive) ?? {
    id: '',
    userFullname: '',
    isActive: true,
    laboratoryName: '',
    userEmail: '',
  };

  const handleOnOptionClick = (account: AccountOption) => {
    if (onOptionClick) onOptionClick(account);
  };

  const handleOnLogoutClick = () => {
    if (onLogoutClick) onLogoutClick();
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleLanguageChange = (locale: Locale) => {
    if (onLanguageChange) onLanguageChange(locale);
  };

  const renderOptions = () => {
    const noResultsFound = filteredAccounts.length === 0 && query !== '';

    if (noResultsFound)
      return (
        <span className="e-text-neutral-300 e-text-sm e-text-center e-p-3 e-block">
          {translations[locale].noResults}
        </span>
      );

    return filteredAccounts
      .filter((account) => !account.isActive)
      .map((account, index) => {
        return (
          <AccountItem
            key={index}
            locale={locale}
            className="e-p-4 hover:e-bg-neutral-800 e-rounded-lg e-cursor-pointer"
            onClick={() => handleOnOptionClick(account)}
            userFullname={account.userFullname}
            laboratoryName={account.laboratoryName}
            newStudyCount={account.newStudyCount}
            urgentStudyCount={account.urgentStudyCount}
          />
        );
      });
  };

  /* Render JSX */
  return (
    <div className={classes.container}>
      {/* CURRENT ACCOUNT */}
      {query === '' && (
        <div className="e-flex e-w-full e-items-center e-gap-2 e-mb-6">
          <Icon icon={IconCatalog.checkRounded} className="e-text-primary-500" width="24px" height="24px" />
          <AccountItem
            locale={locale}
            userFullname={currentAccount.userFullname}
            isActive
            laboratoryName={currentAccount.laboratoryName}
            newStudyCount={currentAccount.newStudyCount}
            urgentStudyCount={currentAccount.urgentStudyCount}
            userEmail={currentAccount.userEmail}
          />
        </div>
      )}

      {/* OTHER ACCOUNTS */}
      {accounts.length > MIN_ITEMS_TO_DISPLAY && (
        <>
          <div className="e-text-sm e-font-semi-bold e-text-left e-text-neutral-200 e-mb-2">
            {translations[locale].otherAccounts}
          </div>
          {accounts.length > MAX_ITEMS_TO_DISPLAY && (
            <div className="e-border-b e-border-neutral-500 e-mb-2">
              <TextInput
                startIcon={IconCatalog.search}
                value={query}
                borderless
                placeholder={translations[locale].searchPlaceholder}
                onChange={handleFilterChange}
              />
            </div>
          )}

          <Scrollable
            data-testid={dataTestId}
            role="listbox"
            style={getMaxHeightByItems(LIST_LENGTH, MAX_ITEMS_TO_DISPLAY, '300px')}
            className={classes.list}>
            {renderOptions()}
          </Scrollable>
          <Divider spacing={5} />
        </>
      )}

      <LanguageSelector locale={locale} onChange={handleLanguageChange} />

      {/* FOOTER */}
      <div className="e-flex e-items-center e-mt-6">
        <Button variant={ButtonVariant.secondary} size={ButtonSize.xs} isFullWidth onClick={handleOnLogoutClick}>
          {translations[locale].logOut}
        </Button>
      </div>
    </div>
  );
};
