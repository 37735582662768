import React from 'react';
import { CompanyAndProductLogoSize } from './CompanyAndProductLogoSize';

export interface AdminProps {
  /**
   * Specify the size of the logo.
   * @default md
   */
  size?: CompanyAndProductLogoSize;
}

const AdminSizes: Record<CompanyAndProductLogoSize, { width: number; height: number }> = {
  [CompanyAndProductLogoSize.md]: {
    width: 175,
    height: 34,
  },
  [CompanyAndProductLogoSize.sm]: {
    width: 106,
    height: 20,
  },
};

/**
 * Admin component for the admin product.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-07-17
 */
export const Admin = ({ size = CompanyAndProductLogoSize.md }: AdminProps) => {
  return (
    <svg
      fill="currentColor"
      width={AdminSizes[size].width}
      height={AdminSizes[size].height}
      viewBox="0 0 175 34"
      preserveAspectRatio="xMinYMin meet"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7181 25.7037C24.4916 25.7037 22.6192 24.8726 21.1027 23.2114C19.5851 21.5507 18.8271 19.5393 18.8271 17.1786C18.8271 14.8185 19.5851 12.8076 21.1027 11.1458C22.6192 9.48516 24.4916 8.65401 26.7181 8.65401C27.8313 8.65401 28.8573 8.90046 29.7959 9.39171C30.734 9.88352 31.4543 10.4797 31.9569 11.1791V0.785156H35.5586V25.3753H31.9569V23.1786C31.4543 23.8786 30.734 24.4742 29.7959 24.9655C28.8573 25.4573 27.8313 25.7037 26.7181 25.7037ZM23.8689 20.9163C24.7863 21.9223 25.9541 22.425 27.3729 22.425C28.7918 22.425 29.9591 21.9223 30.8764 20.9163C31.7932 19.9114 32.2516 18.6655 32.2516 17.1786C32.2516 15.6928 31.7932 14.4469 30.8764 13.4409C29.9591 12.4365 28.7918 11.9327 27.3729 11.9327C25.9541 11.9327 24.7863 12.4365 23.8689 13.4409C22.9527 14.4469 22.4943 15.6928 22.4943 17.1786C22.4943 18.6655 22.9527 19.9114 23.8689 20.9163Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54586 25.7037C6.05741 25.7037 4.01099 24.9054 2.40659 23.3097C0.802197 21.7147 0 19.6704 0 17.1786C0 14.7524 0.807108 12.7256 2.42296 11.0972C4.03773 9.46876 6.07869 8.65401 8.54586 8.65401C10.816 8.65401 12.6982 9.37532 14.194 10.8185C15.6892 12.2606 16.4369 14.2496 16.4369 16.7857C16.4369 17.2229 16.4254 17.6163 16.4041 17.966H3.53622C3.62298 19.2988 4.1365 20.3759 5.07512 21.1955C6.0132 22.0152 7.18157 22.425 8.5786 22.425C10.6741 22.425 12.2349 21.5939 13.2608 19.9327L16.0112 21.8999C14.4395 24.436 11.9511 25.7037 8.54586 25.7037ZM3.69993 15.3425H12.8024C12.606 14.2065 12.0984 13.3158 11.2799 12.6704C10.4613 12.0267 9.50631 11.7032 8.41488 11.7032C7.2798 11.7032 6.27023 12.0207 5.38618 12.654C4.50213 13.2884 3.94004 14.1846 3.69993 15.3425Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2886 23.3912C42.893 24.9868 44.9394 25.7852 47.4279 25.7852C50.8331 25.7852 53.3216 24.5174 54.8932 21.9813L52.1428 20.0141C51.1169 21.6753 49.5561 22.5065 47.4606 22.5065C46.0636 22.5065 44.8952 22.0966 43.9571 21.277C43.0185 20.4573 42.505 19.3802 42.4182 18.0475H55.2861C55.3074 17.6977 55.3189 17.3043 55.3189 16.8671C55.3189 14.3311 54.5712 12.342 53.076 10.8999C51.5802 9.45674 49.698 8.73543 47.4279 8.73543C44.9607 8.73543 42.9197 9.55018 41.305 11.1786C39.6891 12.807 38.882 14.8338 38.882 17.26C38.882 19.7518 39.6842 21.7961 41.2886 23.3912ZM51.6844 15.424H42.5819C42.822 14.266 43.3841 13.3699 44.2682 12.7354C45.1522 12.1021 46.1618 11.7846 47.2969 11.7846C48.3883 11.7846 49.3433 12.1081 50.1619 12.7518C50.9804 13.3972 51.488 14.2879 51.6844 15.424Z"
      />
      <path d="M58.1184 8.98188H61.7201V11.2114C62.7455 9.50647 64.4044 8.65346 66.697 8.65346C68.5955 8.65346 70.1126 9.27696 71.2482 10.5223C72.3827 11.7693 72.9508 13.43 72.9508 15.5065V25.3753H69.3486V16.0972C69.3486 14.7852 69.0424 13.7638 68.4318 13.0311C67.8206 12.2988 66.9916 11.9322 65.9439 11.9322C64.6997 11.9322 63.6841 12.4032 62.8988 13.342C62.1124 14.2824 61.7201 15.6048 61.7201 17.3097V25.3753H58.1184V8.98188Z" />
      <path d="M154.099 4.26115C153.595 4.26115 153.168 4.09719 152.818 3.76926C152.489 3.41948 152.325 3.00411 152.325 2.52315C152.325 2.0422 152.489 1.63776 152.818 1.30984C153.168 0.960049 153.595 0.785156 154.099 0.785156C154.58 0.785156 154.985 0.960049 155.314 1.30984C155.642 1.63776 155.806 2.0422 155.806 2.52315C155.806 3.00411 155.642 3.41948 155.314 3.76926C154.985 4.09719 154.58 4.26115 154.099 4.26115Z" />
      <path d="M152.916 25.5762V9.18001H155.215V25.5762H152.916Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.1278 25.9041C86.829 25.9041 84.8915 25.0734 83.3152 23.4119C81.7388 21.7504 80.9507 19.7392 80.9507 17.3781C80.9507 15.0171 81.7388 13.0058 83.3152 11.3443C84.8915 9.68282 86.829 8.85208 89.1278 8.85208C90.3757 8.85208 91.547 9.13628 92.6417 9.70469C93.7582 10.2731 94.6121 11.0164 95.2032 11.9346V9.18001H97.502V25.5762H95.2032V22.8216C94.6121 23.7398 93.7582 24.4831 92.6417 25.0515C91.547 25.6199 90.3757 25.9041 89.1278 25.9041ZM84.99 21.9362C86.1284 23.1605 87.5843 23.7726 89.3577 23.7726C91.131 23.7726 92.576 23.1605 93.6925 21.9362C94.831 20.6901 95.4002 19.1708 95.4002 17.3781C95.4002 15.5855 94.831 14.077 93.6925 12.8528C92.576 11.6066 91.131 10.9836 89.3577 10.9836C87.5843 10.9836 86.1284 11.6066 84.99 12.8528C83.8734 14.077 83.3152 15.5855 83.3152 17.3781C83.3152 19.1708 83.8734 20.6901 84.99 21.9362Z"
      />
      <path d="M145.237 15.6401V25.5762H147.536V15.3122C147.536 13.3884 147.022 11.8362 145.993 10.6557C144.985 9.45328 143.628 8.85208 141.92 8.85208C140.607 8.85208 139.468 9.19094 138.505 9.86865C137.564 10.5245 136.885 11.4208 136.469 12.5576C136.075 11.399 135.429 10.4917 134.531 9.83586C133.656 9.18001 132.605 8.85208 131.379 8.85208C130.24 8.85208 129.222 9.12535 128.325 9.67189C127.427 10.2184 126.737 10.9727 126.256 11.9346V9.18001H123.957V25.5762H126.256V17.4109C126.256 15.4652 126.705 13.913 127.602 12.7544C128.522 11.5739 129.704 10.9836 131.149 10.9836C132.244 10.9836 133.086 11.4099 133.678 12.2625C134.291 13.1151 134.597 14.241 134.597 15.6401V25.5762H136.896V17.4109C136.896 15.4652 137.334 13.913 138.21 12.7544C139.107 11.5739 140.289 10.9836 141.756 10.9836C142.873 10.9836 143.727 11.4099 144.318 12.2625C144.931 13.1151 145.237 14.241 145.237 15.6401Z" />
      <path d="M160.87 25.5762V9.18001H163.169V11.9346C164.285 9.87958 166.08 8.85208 168.554 8.85208C170.371 8.85208 171.849 9.43142 172.988 10.5901C174.148 11.7487 174.728 13.3228 174.728 15.3122V25.5762H172.429V15.6401C172.429 14.2191 172.046 13.0932 171.28 12.2625C170.536 11.4099 169.539 10.9836 168.292 10.9836C166.803 10.9836 165.577 11.5629 164.613 12.7216C163.65 13.8584 163.169 15.4215 163.169 17.4109V25.5762H160.87Z" />
      <path d="M120.635 2.91372C119.561 2.91372 118.691 3.77582 118.691 4.8401V10.9863C118.164 10.3105 117.343 9.30475 116.543 8.91096V4.8401C116.543 2.60511 118.379 0.785156 120.635 0.785156H121.183V2.91372L120.635 2.91372Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.335 23.3745C105.925 24.9816 107.934 25.7852 110.361 25.7852C112.788 25.7852 114.797 24.9816 116.387 23.3745C116.412 23.3497 116.432 23.3249 116.452 23.3L116.473 23.2745L116.483 23.2628V25.4925H118.739L118.739 18.1808C118.755 17.9521 118.771 17.7231 118.771 17.4837C118.771 17.2444 118.755 17.0157 118.739 16.7869L118.739 16.6323H118.734C118.562 14.6794 117.778 12.9978 116.387 11.5929C114.797 9.98586 112.788 9.18234 110.361 9.18234C107.934 9.18234 105.925 9.98586 104.335 11.5929C102.746 13.2 101.951 15.1636 101.951 17.4837C101.951 19.8039 102.746 21.7675 104.335 23.3745ZM105.957 13.0616C107.085 11.859 108.551 11.2577 110.356 11.2577C112.16 11.2577 113.626 11.859 114.754 13.0616C115.882 14.2643 116.446 15.7383 116.446 17.4837C116.446 19.2292 115.882 20.7032 114.754 21.9058C113.626 23.1085 112.16 23.7098 110.356 23.7098C108.551 23.7098 107.085 23.1085 105.957 21.9058C104.829 20.7032 104.265 19.2292 104.265 17.4837C104.271 15.7383 104.835 14.2643 105.957 13.0616Z"
      />
    </svg>
  );
};
