import React from 'react';
import cn from 'classnames';
import { Content as RadixContent, TooltipContentProps as RadixTooltipContentProps } from '@radix-ui/react-tooltip';

export interface TooltipContentProps extends RadixTooltipContentProps {}

export const Content = React.forwardRef<HTMLDivElement, TooltipContentProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(' e-bg-neutral-600 e-rounded-lg e-border-neutral-500 e-shadow-lg e-border e-p-2', className),
  };
  return (
    <RadixContent className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixContent>
  );
});
