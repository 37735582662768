import React, { Fragment, useEffect } from 'react';
import cn from 'classnames';
import { useToast } from '../../../common';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Button, ButtonSize, ButtonVariant } from '../../Buttons';
import { Transition } from '@headlessui/react';

export type ActionType = {
  /**
   * Set the button action label.
   */
  label: string;

  /**
   * The shape of the button action.
   */
  variant?: ButtonVariant;

  /**
   * Provide a handler that is called when the action button was clicked.
   */
  onActionClick: () => void;
};

export enum ToastVariant {
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

const IconVariants: Record<ToastVariant, IconCatalog> = {
  [ToastVariant.neutral]: IconCatalog.infoFilled,
  [ToastVariant.success]: IconCatalog.checkRounded,
  [ToastVariant.warning]: IconCatalog.warning,
  [ToastVariant.error]: IconCatalog.error,
};

const IconVariantColors: Record<ToastVariant, string> = {
  [ToastVariant.neutral]: 'e-text-primary-400',
  [ToastVariant.success]: 'e-text-success-500',
  [ToastVariant.warning]: 'e-text-warning-500',
  [ToastVariant.error]: 'e-text-error-500',
};

export interface ToastProps {
  /**
   * Set an idntifier when you have more than one Toast on the screen.
   */
  id?: string | number;

  /**
   * Descriptive label to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set a title
   */
  title: string;

  /**
   * Set a subtitle text
   */
  subtitle?: string | JSX.Element;

  /**
   * The shape of the component.
   */
  variant?: ToastVariant;

  /**
   * Whether the Tag has a contextual icon
   */
  hasIcon?: boolean;

  /**
   * Set extra actions
   */
  actions?: Array<ActionType>;

  /**
   * Whether the Toast has a close button
   */
  hasCloseBtn?: boolean;

  /**
   * Set a time to auto delete Toast
   */
  dismissInterval?: number;

  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onClose?: () => void;
}

/**
 * The Toast component is a non-intrusive, time-limited message or notification element used to
 * display brief information to users in software applications or websites.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-08-23
 */
export const Toast = ({
  id,
  ariaLabel,
  className,
  title,
  subtitle,
  variant = ToastVariant.neutral,
  hasIcon = true,
  actions,
  hasCloseBtn = true,
  dismissInterval,
  onClose,
}: ToastProps) => {
  const classes = {
    container: cn(
      className,
      'e-p-4 e-flex e-flex-col e-gap-2',
      'e-w-100 e-min-h-12',
      'e-rounded-lg e-bg-neutral-900 e-shadow-toast',
      {
        'e-items-start': subtitle,
        'e-items-center': !subtitle,
      },
    ),
    body: cn('e-flex e-w-full e-gap-2', {
      'e-justify-start e-content-center': subtitle,
      'e-items-center': !subtitle,
    }),
    icon: cn('e-w-5 e-h-5 e-shrink-0 e-grow-0', IconVariantColors[variant]),
    content: cn('e-flex e-items-start e-flex-col e-flex-1 e-gap-1.5'),
  };

  const { deleteToast } = useToast();

  useEffect(() => {
    if (!dismissInterval) return;

    const timer = setTimeout(() => {
      if (id) deleteToast(id);
    }, dismissInterval);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseBtnClick = () => {
    if (!hasCloseBtn) return;
    if (id) deleteToast(id);
    if (onClose) onClose();
  };

  /* Render JSX */
  return (
    <Transition
      as={Fragment}
      appear
      show
      enter="e-transition-all e-ease-in-out e-duration-500 e-delay-[200ms]"
      enterFrom="e-opacity-0 e-translate-y-6"
      enterTo="e-opacity-100 e-translate-y-0"
      leave="e-transition-all e-ease-in-out e-duration-100"
      leaveFrom="e-opacity-100"
      leaveTo="e-opacity-0">
      <div
        className={classes.container}
        role="alert"
        aria-label={ariaLabel}
        aria-labelledby={`title-${id}`}
        aria-describedby={`description-${id}`}
        tabIndex={0}>
        <div className={classes.body}>
          {/* ICON */}
          {hasIcon && <Icon className={classes.icon} icon={IconVariants[variant]} width={32} height={32} />}

          {/* CONTENT */}
          <div className={classes.content}>
            {/* TITLE */}
            <div id={`title-${id}`} className="e-font-medium e-text-base e-leading-none e-text-base-white">
              {title}
            </div>

            {/* DESCRIPTION */}
            {subtitle && (
              <div id={`description-${id}`} className="e-text-sm e-text-neutral-200">
                {subtitle}
              </div>
            )}
          </div>

          {/* CLOSE BTN */}
          {hasCloseBtn && (
            <Button
              className="e-self-start"
              size={ButtonSize.xs}
              variant={ButtonVariant.ghostNeutral}
              onClick={handleCloseBtnClick}
              startIcon={IconCatalog.close}
            />
          )}
        </div>

        {/* ACTION */}
        {actions && (
          <div className="e-flex e-justify-end e-w-full e-gap-3">
            {actions.map((action, index) => (
              <Button
                key={index}
                size={ButtonSize.xs}
                variant={action.variant || ButtonVariant.tertiaryNeutral}
                onClick={action.onActionClick}>
                {action.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Transition>
  );
};
