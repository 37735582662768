import React, { useMemo } from 'react';
import cn from 'classnames';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { SimpleTooltipProps, TooltipPosition } from '../SimpleTooltip/SimpleTooltip';

interface DynamicTooltipProps
  extends Omit<SimpleTooltipProps, 'title' | 'description' | 'titleClassName' | 'descriptionClassName'> {
  /**
   * The description of the tooltip
   */
  content: React.ReactNode;

  /**
   * The trigger of the tooltip
   */
  children: React.ReactNode;
}

/**
 * This component is a wrapper of the BaseTooltip component, it allows to create a tooltip with a dynamic description
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2023-09-13
 */
export const DynamicTooltip = ({
  open,
  onClick,
  children,
  controlled,
  content,
  onOpenChange,
  contentClassName,
  preventClose = false,
  delayDuration = 700,
  position = TooltipPosition.RIGHT,
}: DynamicTooltipProps) => {
  const classes = {
    content: cn('e-flex e-flex-col e-break-all e-max-w-xs e-m-1', contentClassName),
  };

  const handlePointerDown = (event: { preventDefault: () => void }) => {
    if (preventClose) event.preventDefault();
  };

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) onClick(event);
  };

  const handleOnOpenChange = (open: boolean) => {
    if (onOpenChange) onOpenChange(open);
  };

  const rootProperties = useMemo(() => {
    if (controlled)
      return {
        onOpenChange: handleOnOpenChange,
        controlled,
        open,
      };
    return { onOpenChange: handleOnOpenChange };
  }, [controlled, open]);

  return (
    <BaseTooltip.Provider delayDuration={delayDuration}>
      <BaseTooltip.Root {...rootProperties}>
        <BaseTooltip.Trigger asChild>{children}</BaseTooltip.Trigger>
        <BaseTooltip.Portal>
          <BaseTooltip.Content
            side={position}
            className={classes.content}
            onPointerDownOutside={handlePointerDown}
            onClick={handleClick}>
            {content}
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
};

export default DynamicTooltip;

/**
 * Notes:
 * In order to prevent the tooltip from closing when the trigger is clicked, the onPointerDownOutside event must be used.
 * Don't forget to add the e.preventDefault() on the trigger element.
 * @reference https://github.com/radix-ui/primitives/issues/2029
 */
