import { useEffect } from 'react';

interface UseHorizontalScroll {
  (
    /*
     * Reference to the element.
     */
    elemRef: React.RefObject<HTMLElement>,

    /*
     * If true, the element will be scrollable.
     */
    isScrollable?: boolean,

    /*
     * Callback function to be executed on scroll
     */
    onScroll?: (event: WheelEvent) => void,
  ): void;
}

export const useHorizontalScroll: UseHorizontalScroll = (elemRef, isScrollable = true, onScroll) => {
  useEffect(() => {
    const element = elemRef.current;
    if (!element || !isScrollable) return;

    const handleScroll = (event: WheelEvent) => {
      // Prevent forced horizontal scroll on an user horizontal scroll
      if (Math.abs(event.deltaX) > 0) return;

      if (element.getBoundingClientRect().top <= window.innerHeight && element.getBoundingClientRect().bottom >= 0) {
        element.scrollLeft += event.deltaY;
        event.preventDefault();

        if (onScroll) onScroll(event);
      }
    };

    element.addEventListener('wheel', handleScroll, { passive: false });
    return () => element.removeEventListener('wheel', handleScroll);
  }, [elemRef, isScrollable, onScroll]);
};
