import React from 'react';
import cn from 'classnames';
import { SubTrigger as RadixSubTrigger, MenuSubTriggerProps } from '@radix-ui/react-dropdown-menu';

export interface SubTriggerProps extends MenuSubTriggerProps {}

export const SubTrigger = React.forwardRef<HTMLDivElement, SubTriggerProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(
      'hover:e-outline-none e-flex hover:e-bg-neutral-900 e-cursor-pointer e-text-sm e-text-neutral-50 e-py-3 e-px-4',
      className,
    ),
  };
  return (
    <RadixSubTrigger className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixSubTrigger>
  );
});
