import React from 'react';
import cn from 'classnames';

import { Spinner, SpinnerSize } from '../Spinner/Spinner';
import { CompanyLogo, CompanyLogoSize } from '../../CompanyLogo/CompanyLogo';

export enum BrandedSpinnerSize {
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

const companyLogoSize: Record<BrandedSpinnerSize, CompanyLogoSize> = {
  [BrandedSpinnerSize.lg]: CompanyLogoSize.sm,
  [BrandedSpinnerSize.xl]: CompanyLogoSize.sm,
  [BrandedSpinnerSize.xxl]: CompanyLogoSize.md,
};

const spacerSize: Record<BrandedSpinnerSize, string> = {
  [BrandedSpinnerSize.lg]: 'e-space-y-2',
  [BrandedSpinnerSize.xl]: 'e-space-y-4',
  [BrandedSpinnerSize.xxl]: 'e-space-y-4',
};

export interface BrandedSpinnerProps {
  /**
   * Specify the size of the spinner.
   * @default lg
   */
  size?: BrandedSpinnerSize;
  /**
   * Specify an optional className to be added to the component
   * @default ''
   */
  className?: string;
  /**
   * Specify is full screen
   * @default false
   */
  isFullScreen?: boolean;
}

export const BrandedSpinner = ({
  className,
  isFullScreen = false,
  size = BrandedSpinnerSize.lg,
}: BrandedSpinnerProps) => {
  const classes = {
    wrapper: cn(className, 'e-flex e-flex-col e-items-center e-justify-center', spacerSize[size], {
      'e-h-full e-w-full': isFullScreen,
    }),
  };

  return (
    <div className={classes.wrapper}>
      <Spinner size={SpinnerSize[size]} />
      <CompanyLogo hasLink={false} size={companyLogoSize[size]} />
    </div>
  );
};
