import React from 'react';
import cn from 'classnames';
import { SubContent as RadixSubContent, MenuSubContentProps } from '@radix-ui/react-dropdown-menu';

export interface SubContentProps extends MenuSubContentProps {}

export const SubContent = React.forwardRef<HTMLDivElement, SubContentProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(
      'e-overflow-hidden e-bg-neutral-600 e-rounded-lg e-border-neutral-500 e-shadow-md e-border',
      className,
    ),
  };
  return (
    <RadixSubContent className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixSubContent>
  );
});
