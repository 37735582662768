import React from 'react';
import cn from 'classnames';
import {
  Content as RadixContent,
  DropdownMenuContentProps as RadixDropdownMenuContentProps,
} from '@radix-ui/react-dropdown-menu';

export interface DropdownMenuContentProps extends RadixDropdownMenuContentProps {}

export const Content = React.forwardRef<HTMLDivElement, DropdownMenuContentProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(
      'e-overflow-hidden e-bg-neutral-600 e-rounded-lg e-border-neutral-500 e-shadow-md e-border',
      className,
    ),
  };
  return (
    <RadixContent className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixContent>
  );
});
