import React from 'react';
import { CompanyAndProductLogoSize } from './CompanyAndProductLogoSize';

export interface IntelligenceProps {
  /**
   * Specify the size of the logo.
   * @default md
   */
  size?: CompanyAndProductLogoSize;
}

const IntelligenceSizes: Record<CompanyAndProductLogoSize, { width: number; height: number }> = {
  [CompanyAndProductLogoSize.md]: {
    width: 245,
    height: 32,
  },
  [CompanyAndProductLogoSize.sm]: {
    width: 148,
    height: 20,
  },
};

/**
 * Intelligence component for the intelligence product.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-07-17
 */
export const Intelligence = ({ size = CompanyAndProductLogoSize.md }: IntelligenceProps) => {
  return (
    <svg
      fill="currentColor"
      width={IntelligenceSizes[size].width}
      height={IntelligenceSizes[size].height}
      viewBox="0 0 245 32"
      preserveAspectRatio="xMinYMin meet"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7181 25.0145C24.4916 25.0145 22.6192 24.1834 21.1027 22.5222C19.5851 20.8615 18.8271 18.85 18.8271 16.4894C18.8271 14.1293 19.5851 12.1184 21.1027 10.4566C22.6192 8.79595 24.4916 7.9648 26.7181 7.9648C27.8313 7.9648 28.8573 8.21125 29.7959 8.7025C30.734 9.19431 31.4543 9.79048 31.9569 10.4899V0.0959473H35.5586V24.6861H31.9569V22.4894C31.4543 23.1894 30.734 23.785 29.7959 24.2763C28.8573 24.7681 27.8313 25.0145 26.7181 25.0145ZM23.8689 20.2271C24.7863 21.2331 25.9541 21.7358 27.3729 21.7358C28.7918 21.7358 29.9591 21.2331 30.8764 20.2271C31.7932 19.2222 32.2516 17.9763 32.2516 16.4894C32.2516 15.0036 31.7932 13.7577 30.8764 12.7517C29.9591 11.7473 28.7918 11.2435 27.3729 11.2435C25.9541 11.2435 24.7863 11.7473 23.8689 12.7517C22.9527 13.7577 22.4943 15.0036 22.4943 16.4894C22.4943 17.9763 22.9527 19.2222 23.8689 20.2271Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54586 25.0145C6.05741 25.0145 4.01099 24.2162 2.40659 22.6205C0.802197 21.0255 0 18.9812 0 16.4894C0 14.0632 0.807108 12.0364 2.42296 10.408C4.03773 8.77955 6.07869 7.9648 8.54586 7.9648C10.816 7.9648 12.6982 8.68611 14.194 10.1293C15.6892 11.5714 16.4369 13.5604 16.4369 16.0965C16.4369 16.5337 16.4254 16.9271 16.4041 17.2768H3.53622C3.62298 18.6096 4.1365 19.6867 5.07512 20.5063C6.0132 21.326 7.18157 21.7358 8.5786 21.7358C10.6741 21.7358 12.2349 20.9047 13.2608 19.2435L16.0112 21.2107C14.4395 23.7468 11.9511 25.0145 8.54586 25.0145ZM3.69993 14.6533H12.8024C12.606 13.5173 12.0984 12.6265 11.2799 11.9812C10.4613 11.3375 9.50631 11.014 8.41488 11.014C7.2798 11.014 6.27023 11.3315 5.38618 11.9648C4.50213 12.5992 3.94004 13.4954 3.69993 14.6533Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2886 22.702C42.893 24.2976 44.9394 25.0959 47.4279 25.0959C50.8331 25.0959 53.3216 23.8282 54.8932 21.2921L52.1428 19.3249C51.1169 20.9861 49.5561 21.8173 47.4606 21.8173C46.0636 21.8173 44.8952 21.4074 43.9571 20.5877C43.0185 19.7681 42.505 18.691 42.4182 17.3582H55.2861C55.3074 17.0085 55.3189 16.6151 55.3189 16.1779C55.3189 13.6418 54.5712 11.6528 53.076 10.2107C51.5802 8.76753 49.698 8.04622 47.4279 8.04622C44.9607 8.04622 42.9197 8.86097 41.305 10.4894C39.6891 12.1178 38.882 14.1446 38.882 16.5708C38.882 19.0626 39.6842 21.1069 41.2886 22.702ZM51.6844 14.7347H42.5819C42.822 13.5768 43.3841 12.6806 44.2682 12.0462C45.1522 11.4129 46.1618 11.0954 47.2969 11.0954C48.3883 11.0954 49.3433 11.4189 50.1619 12.0626C50.9804 12.708 51.488 13.5987 51.6844 14.7347Z"
      />
      <path d="M58.1184 8.29267H61.7201V10.5222C62.7455 8.81726 64.4044 7.96425 66.697 7.96425C68.5955 7.96425 70.1126 8.58775 71.2482 9.83311C72.3827 11.0801 72.9508 12.7408 72.9508 14.8173V24.6861H69.3486V15.408C69.3486 14.0959 69.0424 13.0746 68.4318 12.3418C67.8206 11.6096 66.9916 11.2429 65.9439 11.2429C64.6997 11.2429 63.6841 11.714 62.8988 12.6528C62.1124 13.5932 61.7201 14.9156 61.7201 16.6205V24.6861H58.1184V8.29267Z" />
      <path d="M81.4271 3.55491C81.7456 3.87365 82.14 4.03149 82.6123 4.03149C83.0631 4.03149 83.4431 3.87365 83.7508 3.55491C84.058 3.2377 84.2124 2.85337 84.2124 2.4019C84.2124 1.95197 84.058 1.56764 83.7508 1.24889C83.4431 0.931686 83.0631 0.772827 82.6123 0.772827C82.14 0.772827 81.7456 0.931686 81.4271 1.24889C81.1092 1.56764 80.9507 1.95197 80.9507 2.4019C80.9507 2.85337 81.1092 3.2377 81.4271 3.55491Z" />
      <path d="M83.6585 8.64352H81.5046V24.017H83.6585V8.64352Z" />
      <path d="M89.0281 24.2173V8.84389H91.182V11.4271C92.2282 9.50034 93.9104 8.53642 96.2285 8.53642C97.9306 8.53642 99.3209 9.08013 100.398 10.166C101.475 11.2529 102.013 12.7288 102.013 14.5941V24.2173H99.8594V14.901C99.8594 13.5692 99.5056 12.5079 98.7978 11.7187C98.0901 10.9301 97.1516 10.5355 95.9823 10.5355C94.5868 10.5355 93.4386 11.0736 92.536 12.1497C91.6328 13.2258 91.182 14.6966 91.182 16.5619V24.2173H89.0281Z" />
      <path d="M109.306 23.0951C110.116 23.8438 111.167 24.2173 112.46 24.2173C113.301 24.2173 114.06 24.0836 114.737 23.8176V21.8806C114.224 22.1066 113.578 22.2188 112.798 22.2188C111.937 22.2188 111.295 22.0184 110.875 21.6192C110.454 21.2195 110.245 20.5482 110.245 19.6053V10.7195H114.737V8.68964H110.245V4.41582H108.09V8.68964H104.798V10.7195H108.09V19.7893C108.09 21.2451 108.495 22.3469 109.306 23.0951Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.63 24.6017C122.27 24.6017 120.363 23.8386 118.907 22.311C117.45 20.7839 116.722 18.8828 116.722 16.607C116.722 14.3527 117.471 12.4567 118.968 10.9193C120.465 9.38196 122.374 8.61329 124.692 8.61329C126.763 8.61329 128.487 9.31022 129.861 10.7041C131.235 12.0985 131.923 13.994 131.923 16.3917V16.9764H118.937C118.937 18.5748 119.47 19.9133 120.538 20.9889C121.604 22.0651 122.968 22.6036 124.63 22.6036C125.86 22.6036 126.871 22.3623 127.661 21.8806C128.45 21.3994 129.132 20.6768 129.707 19.7129L131.461 20.8506C130.004 23.3518 127.727 24.6017 124.63 24.6017ZM119.122 15.0696H129.677C129.471 13.6973 128.907 12.6109 127.984 11.811C127.061 11.011 125.933 10.6118 124.599 10.6118C123.265 10.6118 122.081 11.0167 121.045 11.8258C120.009 12.636 119.368 13.7173 119.122 15.0696Z"
      />
      <path d="M138.108 1.15716H135.954V24.2173H138.108V1.15716Z" />
      <path d="M143.262 1.15716H145.416V24.2173H143.262V1.15716Z" />
      <path d="M150.662 3.75528C150.98 4.07402 151.375 4.23185 151.847 4.23185C152.298 4.23185 152.678 4.07402 152.986 3.75528C153.293 3.43807 153.447 3.05374 153.447 2.60227C153.447 2.15234 153.293 1.768 152.986 1.44926C152.678 1.13205 152.298 0.973194 151.847 0.973194C151.375 0.973194 150.98 1.13205 150.662 1.44926C150.344 1.768 150.185 2.15234 150.185 2.60227C150.185 3.05374 150.344 3.43807 150.662 3.75528Z" />
      <path d="M152.893 8.84389H150.739V24.2173H152.893V8.84389Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.894 22.2803C169.376 23.8284 167.458 24.6017 165.14 24.6017C162.822 24.6017 160.904 23.8284 159.386 22.2803C157.868 20.7327 157.109 18.8423 157.109 16.6075C157.109 14.3732 157.868 12.4828 159.386 10.9347C160.904 9.38709 162.822 8.61329 165.14 8.61329C167.458 8.61329 169.376 9.38709 170.894 10.9347C170.915 10.9557 170.935 10.9769 170.956 10.9981V8.38474H173.11V15.5333C173.151 15.8821 173.171 16.2401 173.171 16.6075C173.171 18.8423 172.412 20.7327 170.894 22.2803ZM169.341 20.8659C170.417 19.7078 170.956 18.2888 170.956 16.6075C170.956 14.9267 170.417 13.5077 169.341 12.349C168.263 11.1914 166.864 10.6118 165.14 10.6118C163.417 10.6118 162.017 11.1914 160.94 12.349C159.863 13.5077 159.324 14.9267 159.324 16.6075C159.324 18.2888 159.863 19.7078 160.94 20.8659C162.017 22.0241 163.417 22.6036 165.14 22.6036C166.864 22.6036 168.263 22.0241 169.341 20.8659Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.141 22.311C179.597 23.8386 181.505 24.6017 183.864 24.6017C186.961 24.6017 189.238 23.3518 190.695 20.8506L188.941 19.7129C188.366 20.6768 187.684 21.3994 186.895 21.8806C186.105 22.3623 185.094 22.6036 183.864 22.6036C182.203 22.6036 180.838 22.0651 179.771 20.9889C178.704 19.9133 178.171 18.5748 178.171 16.9764H191.157V16.3917C191.157 13.994 190.469 12.0985 189.095 10.7041C187.72 9.31022 185.997 8.61329 183.926 8.61329C181.608 8.61329 179.699 9.38196 178.202 10.9193C176.705 12.4567 175.956 14.3527 175.956 16.607C175.956 18.8828 176.684 20.7839 178.141 22.311ZM188.911 15.0696H178.356C178.602 13.7173 179.243 12.636 180.279 11.8258C181.315 11.0167 182.499 10.6118 183.833 10.6118C185.167 10.6118 186.295 11.011 187.218 11.811C188.141 12.6109 188.705 13.6973 188.911 15.0696Z"
      />
      <path d="M194.803 24.2173V8.84389H196.957V11.4271C198.003 9.50034 199.685 8.53642 202.004 8.53642C203.706 8.53642 205.096 9.08013 206.173 10.166C207.25 11.2529 207.789 12.7288 207.789 14.5941V24.2173H205.635V14.901C205.635 13.5692 205.281 12.5079 204.573 11.7187C203.865 10.9301 202.927 10.5355 201.757 10.5355C200.362 10.5355 199.214 11.0736 198.311 12.1497C197.408 13.2258 196.957 14.6966 196.957 16.5619V24.2173H194.803Z" />
      <path d="M214.004 22.2803C215.522 23.8284 217.44 24.6017 219.758 24.6017C221.318 24.6017 222.707 24.243 223.928 23.525C225.149 22.8081 226.108 21.8242 226.805 20.5738L224.928 19.4977C224.436 20.4616 223.744 21.219 222.851 21.7725C221.959 22.3264 220.928 22.6036 219.758 22.6036C218.036 22.6036 216.635 22.0241 215.558 20.8659C214.481 19.7078 213.943 18.2888 213.943 16.607C213.943 14.9267 214.481 13.5077 215.558 12.349C216.635 11.1914 218.036 10.6118 219.758 10.6118C220.928 10.6118 221.959 10.888 222.851 11.4415C223.744 11.9949 224.436 12.7539 224.928 13.7173L226.805 12.6406C226.108 11.3913 225.149 10.4069 223.928 9.68892C222.707 8.972 221.318 8.61329 219.758 8.61329C217.44 8.61329 215.522 9.38709 214.004 10.9347C212.486 12.4828 211.727 14.3732 211.727 16.607C211.727 18.8423 212.486 20.7327 214.004 22.2803Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.944 24.6017C234.584 24.6017 232.677 23.8386 231.221 22.311C229.764 20.7839 229.036 18.8828 229.036 16.607C229.036 14.3527 229.785 12.4567 231.282 10.9193C232.779 9.38196 234.688 8.61329 237.006 8.61329C239.077 8.61329 240.801 9.31022 242.175 10.7041C243.549 12.0985 244.237 13.994 244.237 16.3917V16.9764H231.251C231.251 18.5748 231.785 19.9133 232.852 20.9889C233.918 22.0651 235.282 22.6036 236.944 22.6036C238.174 22.6036 239.185 22.3623 239.975 21.8806C240.764 21.3994 241.446 20.6768 242.021 19.7129L243.775 20.8506C242.318 23.3518 240.041 24.6017 236.944 24.6017ZM231.436 15.0696H241.991C241.785 13.6973 241.221 12.6109 240.298 11.811C239.375 11.011 238.247 10.6118 236.913 10.6118C235.579 10.6118 234.395 11.0167 233.359 11.8258C232.323 12.636 231.682 13.7173 231.436 15.0696Z"
      />
      <path d="M157.493 31.9041H159.545V31.5351C159.545 30.5128 160.377 29.6806 161.401 29.6806H169.106C171.26 29.6806 173.013 27.929 173.013 25.7767V25.253H170.961V25.7767C170.961 26.7991 170.129 27.6308 169.106 27.6308H161.401C159.246 27.6308 157.493 29.3823 157.493 31.5351V31.9041Z" />
    </svg>
  );
};
