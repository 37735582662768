import React from 'react';
import cn from 'classnames';
import { Pacs } from './Pacs';
import { Admin } from './Admin';
import { Management } from './Management';
import { Intelligence } from './Intelligence';
import { CompanyAndProductLogoSize } from './CompanyAndProductLogoSize';

export enum ProductLogoType {
  pacs = 'pacs',
  panel = 'panel',
  management = 'management',
  intelligence = 'intelligence',
}

export interface CompanyAndProductLogoProps {
  /**
   * Specify the size of the logos.
   * @default md
   */
  size?: CompanyAndProductLogoSize;
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
  /**
   * Specify name product
   */
  product: ProductLogoType;
}

/**
 * LogoProduct component for the Brand of the product.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-07-17
 */
export const CompanyAndProductLogo = ({
  product,
  className,
  size = CompanyAndProductLogoSize.md,
}: CompanyAndProductLogoProps) => {
  const classes = {
    wrapper: cn(className, 'e-text-base-white'),
  };
  const logo: Record<ProductLogoType, () => JSX.Element> = {
    [ProductLogoType.pacs]: () => <Pacs size={size} />,
    [ProductLogoType.panel]: () => <Admin size={size} />,
    [ProductLogoType.management]: () => <Management size={size} />,
    [ProductLogoType.intelligence]: () => <Intelligence size={size} />,
  };

  if (!logo[product]) return null;
  return <div className={classes.wrapper}>{logo[product]()}</div>;
};
