import React from 'react';
import cn from 'classnames';
import { useRef, ReactNode } from 'react';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { TooltipPosition } from '../SimpleTooltip/SimpleTooltip';
import { LineClamp, useIsTextClamped } from '../../../../common';

export interface AutoTooltipProps {
  /**
   * The class name of the trigger
   */
  triggerClassName?: string;

  /**
   * The class name of the content
   */
  contentClassName?: string;

  /**
   * The class name of the title
   */
  titleClassName?: string;

  /**
   * The class name of the description
   */
  descriptionClassName?: string;

  /**
   * The title of the tooltip
   */
  title?: string;

  /**
   * The description of the tooltip
   */
  description?: string;

  /**
   * The position of the tooltip
   */
  position?: TooltipPosition;

  /**
   * The number of lines to display
   */
  numberOfLines?: number;

  /**
   * The trigger of the tooltip
   */
  children: ReactNode;
}

/**
 * This component is responsible for displaying a tooltip
 *  When its content overflows over another container element.
 * @author Mauricio Campos <mauricio.campos@evacenter.com>
 * Created at 2023-07-06
 */
export const AutoTooltip = ({
  triggerClassName,
  titleClassName,
  contentClassName,
  descriptionClassName,
  title,
  description,
  position = TooltipPosition.RIGHT,
  numberOfLines = 1,
  children,
}: AutoTooltipProps) => {
  const classes = {
    trigger: cn('e-w-full e-break-all', triggerClassName, LineClamp[numberOfLines]),
    content: cn('e-flex e-flex-col e-max-w-xs e-m-1', contentClassName),
    title: cn('e-mb-1', titleClassName),
    description: cn(descriptionClassName),
  };

  const triggerRef = useRef(null);

  const triggerIsOverflowing = useIsTextClamped(triggerRef);
  // Must be undefined to delegate to the library the logic to show or hide the tooltip
  const activateTooltip = !triggerIsOverflowing ? false : undefined;

  return (
    <BaseTooltip.Provider disableHoverableContent>
      <BaseTooltip.Root open={activateTooltip}>
        <BaseTooltip.Trigger asChild>
          <span className={classes.trigger} ref={triggerRef}>
            {children}
          </span>
        </BaseTooltip.Trigger>
        <BaseTooltip.Portal>
          <BaseTooltip.Content className={classes.content} side={position}>
            {title && <BaseTooltip.Title className={classes.title}>{title}</BaseTooltip.Title>}
            {description && (
              <BaseTooltip.Description className={classes.description}>{description}</BaseTooltip.Description>
            )}
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
};
