import React, { useContext, createContext } from 'react';

export interface SidebarContextType {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

/*  CONTEXT DEFINITION  */
export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

/*   EXPORT USE METHOD   */
export const useSidebarContext = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) throw new Error('useSidebarContext must be wrapped within Sidebar');
  return context;
};
