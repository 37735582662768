import React, {
  Children,
  cloneElement,
  createContext,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modifier, usePopper } from 'react-popper';
import cn from 'classnames';
import { hasProp, useOnClickOutside } from '../../../../common';
import Portal from '../../../Portal/Portal';

interface PopperContextProps {
  isOpen: boolean;
  closePopper?: () => void;
  openPopper?: () => void;
  togglePopper?: () => void;
  popoverElement: HTMLDivElement | null;
  refTriggerNode: RefObject<HTMLElement>;
  menuRef: RefObject<HTMLDivElement>;
}

export const PopperContext = createContext({} as PopperContextProps);

export enum PopoverPlacement {
  topStart = 'top-start',
  top = 'top',
  topEnd = 'top-end',
  leftStart = 'left-start',
  left = 'left',
  leftEnd = 'left-end',
  rightStart = 'right-start',
  right = 'right',
  rightEnd = 'right-end',
  bottomStart = 'bottom-start',
  bottom = 'bottom',
  bottomEnd = 'bottom-end',
}

export interface PopoverProps {
  /**
   * The content displayed inside the popover.
   */
  content: ReactNode;

  /**
   * When true, the popover is manually shown.
   */
  isOpen?: boolean;

  /**
   * Whether has a decorative arrow.
   */
  hasArrow?: boolean;

  /**
   * The position (relative to the target) at which the popover should appear.
   */
  placement?: PopoverPlacement;

  /**
   * Specify an optional className to be added to the menu component
   */
  menuClassName?: string;

  /**
   * Whether the float menu has the same trigger's width
   */
  menuFullWidth?: boolean;

  /**
   * Specify the role of the popover in order to improve accessibility
   */
  role?: string;

  /**
   * Elements to display inside the Navbar.
   */
  children?: ReactNode;

  /**
   * When true, the trigger click handler will be automatically set
   */

  allowTriggerClickBinding?: boolean;

  /**
   * When true, clicking outside the popover will no close it
   */
  disableOnClickOutside?: boolean;

  /**
   * Function called when the use clicks outside the popover container.
   */
  onClickOutside?: (event: any) => void;
}

/**
 * The Popover component displays floating informative and actionable content in relation to a target. Popovers appear either at the top, bottom, left, or right of their target.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * @deprecated Use `PopoverV2` instead
 * Created at 2022-05-25
 */
export const Popover = ({
  content,
  isOpen = false,
  hasArrow = false,
  placement = PopoverPlacement.bottomStart,
  onClickOutside,
  role = 'menu',
  menuClassName,
  menuFullWidth = false,
  children,
  allowTriggerClickBinding = true,
  disableOnClickOutside = false,
}: PopoverProps) => {
  const classes = {
    menu: cn(menuClassName, 'e-shadow-lg e-bg-neutral-600 e-rounded-lg e-z-50'),
    menuContent: cn(
      'e-bg-neutral-600 e-rounded-lg',
      'e-border e-border-neutral-500',
      'e-text-base-white e-text-caption e-text-center',
      'e-transition e-duration-100 e-ease-out',
    ),
    arrowContainer: cn('e-absolute e-w-2 e-h-2 e-bg-neutral-600 e-invisible', {
      '-e-bottom-1': placement.includes(PopoverPlacement.top),
      '-e-top-1': placement.includes(PopoverPlacement.bottom),
      '-e-right-0': placement.includes(PopoverPlacement.left),
      '-e-left-2': placement.includes(PopoverPlacement.right),
    }),
    arrow: cn('e-w-2 e-h-2', 'e-bg-neutral-600 e-absolute', 'e-transform e-rotate-45', 'e-visible'),
  };

  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const refTriggerNode = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(isOpen);

  useOnClickOutside(popoverElement, (event: any) => {
    if (disableOnClickOutside) return;
    if (onClickOutside) return onClickOutside(event);
    closePopper();
  });

  const modifiers = useMemo(
    () => [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right'],
        },
      },
      /**
       * Modifier taken from: https://popper.js.org/docs/v2/modifiers/community-modifiers/
       * It allows to make the popover the same width as the `refTriggerNode`
       */
      menuFullWidth
        ? ({
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${state.elements.reference?.getBoundingClientRect()?.width}px`;
            },
          } as Modifier<'sameWidth'>)
        : {},
    ],
    [menuFullWidth],
  );

  /* Popper config */
  const { styles, attributes, forceUpdate } = usePopper(refTriggerNode.current, popoverElement, {
    placement,
    modifiers,
  });

  useEffect(() => {
    setOpen(isOpen);
    let timeout: NodeJS.Timeout;
    if (forceUpdate) timeout = setTimeout(() => forceUpdate());
    return () => clearTimeout(timeout);
  }, [isOpen]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (forceUpdate) timeout = setTimeout(() => forceUpdate());
    return () => clearTimeout(timeout);
  }, [open]);

  // Adjust popover position if the refTriggerNode size changes
  useEffect(() => {
    if (!refTriggerNode.current || !forceUpdate) return;

    let timeout: NodeJS.Timeout;

    const refTriggerNodeResizeObserver = new ResizeObserver(() => {
      timeout = setTimeout(() => forceUpdate());
    });

    // Start observing for resize
    refTriggerNodeResizeObserver.observe(refTriggerNode.current);

    return () => {
      clearTimeout(timeout);
      if (!refTriggerNode.current) return;
      refTriggerNodeResizeObserver.unobserve(refTriggerNode.current);
    };
  }, [forceUpdate]);

  const handleTriggerClick = (): void => setOpen(!open);

  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const getRootElementProps = () => {
    const hasRootElementClickHandler = hasProp(elements[0].props, 'onClick');
    const baseRootElementProps = {
      ref: refTriggerNode,
    };

    /* Append handle to the trigger component */
    if (allowTriggerClickBinding && !hasRootElementClickHandler)
      return {
        ...baseRootElementProps,
        onClick: handleTriggerClick,
      };

    return baseRootElementProps;
  };

  // TODO: find any
  let elements: any = Children.toArray(children);

  /* Append the trigger ref to the root element */
  elements = cloneElement(elements[0], getRootElementProps());

  return (
    <>
      <PopperContext.Provider
        value={{
          closePopper,
          openPopper,
          togglePopper: handleTriggerClick,
          isOpen: open,
          popoverElement,
          refTriggerNode,
          menuRef,
        }}>
        {elements}
        {open && (
          <Portal>
            <div ref={menuRef}>
              <div
                role={role}
                className={classes.menu}
                ref={setPopoverElement}
                style={styles.popper}
                {...attributes.popper}>
                <div className={classes.menuContent} onMouseDown={(event) => event?.stopPropagation()}>
                  {content}
                  {hasArrow && (
                    <div
                      id="arrow"
                      ref={setArrowElement}
                      style={styles.arrow}
                      data-popper-arrow
                      className={classes.arrowContainer}>
                      <span className={classes.arrow}></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Portal>
        )}
      </PopperContext.Provider>
    </>
  );
};
