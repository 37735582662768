import React from 'react';
import cn from 'classnames';
import { Item as RadixItem, DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';

export interface MenuItemProps extends DropdownMenuItemProps {}

export const Item = React.forwardRef<HTMLDivElement, MenuItemProps>((props: MenuItemProps, forwardedRef) => {
  const { className, children, disabled, ...rest } = props;
  const classes = {
    container: cn(
      'hover:e-outline-none e-flex e-text-sm e-text-neutral-50 e-py-3 e-px-4',
      {
        'e-opacity-40 e-cursor-default': disabled,
        'hover:e-bg-neutral-900 e-cursor-pointer': !disabled,
      },
      className,
    ),
  };
  return (
    <RadixItem className={classes.container} ref={forwardedRef} disabled={disabled} {...rest}>
      {children}
    </RadixItem>
  );
});
