import React, { HTMLAttributes, ReactNode, useState } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../Icon/Icon';

export enum TagV2Size {
  '2xs' = '2xs',
  xs = 'xs',
  base = 'base',
  lg = 'lg',
}

const Sizes: Record<TagV2Size, string> = {
  [TagV2Size['2xs']]: 'e-h-4 e-px-1',
  [TagV2Size.xs]: 'e-h-5 e-py-0.5 e-px-2',
  [TagV2Size.base]: 'e-h-6 e-py-1 e-px-2',
  [TagV2Size.lg]: 'e-h-10 e-py-2 e-px-4',
};

const IconSizes: Record<TagV2Size, number> = {
  [TagV2Size['2xs']]: 16,
  [TagV2Size.xs]: 16,
  [TagV2Size.base]: 16,
  [TagV2Size.lg]: 24,
};

const LabelSizes: Record<TagV2Size, string> = {
  [TagV2Size['2xs']]: 'e-text-2xs',
  [TagV2Size.xs]: 'e-text-2xs',
  [TagV2Size.base]: 'e-text-base',
  [TagV2Size.lg]: 'e-text-base',
};

export enum TagV2Type {
  info = 'info',
  neutral = 'neutral',
  warning = 'warning',
  error = 'error',
}

export enum TagV2Appearance {
  subtle = 'subtle',
  moderate = 'moderate',
  moderateAlt = 'moderateAlt',
  strong = 'strong',
}

const Variants: Record<TagV2Type, Record<TagV2Appearance, string>> = {
  [TagV2Type.info]: {
    [TagV2Appearance.subtle]: 'e-bg-neutral-600 e-text-neutral-300',
    [TagV2Appearance.moderate]: 'e-bg-neutral-600 e-text-neutral-100',
    [TagV2Appearance.moderateAlt]: 'e-bg-base-black e-text-base-white',
    [TagV2Appearance.strong]: 'e-border e-border-neutral-300 e-bg-neutral-600 e-text-neutral-100',
  },
  [TagV2Type.neutral]: {
    [TagV2Appearance.subtle]: 'e-bg-neutral-600 e-text-primary-200',
    [TagV2Appearance.moderate]: 'e-bg-primary-900 e-text-neutral-100',
    [TagV2Appearance.moderateAlt]: 'e-bg-primary-900 e-text-primary-300',
    [TagV2Appearance.strong]: 'e-border e-border-primary-500 e-bg-primary-900 e-text-neutral-100',
  },
  [TagV2Type.warning]: {
    [TagV2Appearance.subtle]: 'e-bg-neutral-600 e-text-warning-500',
    [TagV2Appearance.moderate]: 'e-bg-warning-900 e-text-neutral-100',
    [TagV2Appearance.moderateAlt]: 'e-bg-warning-900 e-text-warning-400',
    [TagV2Appearance.strong]: 'e-border e-border-warning-500 e-bg-warning-900 e-text-neutral-100',
  },
  [TagV2Type.error]: {
    [TagV2Appearance.subtle]: 'e-bg-neutral-600 e-text-error-500',
    [TagV2Appearance.moderate]: 'e-bg-error-900 e-text-neutral-100',
    [TagV2Appearance.moderateAlt]: 'e-bg-error-900 e-text-error-400',
    [TagV2Appearance.strong]: 'e-border e-border-error-500 e-bg-error-900 e-text-neutral-100',
  },
};

const IconVariants: Record<TagV2Type, Record<TagV2Appearance, string>> = {
  [TagV2Type.info]: {
    [TagV2Appearance.subtle]: 'e-text-neutral-300',
    [TagV2Appearance.moderate]: 'e-text-neutral-200',
    [TagV2Appearance.moderateAlt]: 'e-text-base-white',
    [TagV2Appearance.strong]: 'e-text-neutral-200',
  },
  [TagV2Type.neutral]: {
    [TagV2Appearance.subtle]: 'e-text-primary-300',
    [TagV2Appearance.moderate]: 'e-text-primary-300',
    [TagV2Appearance.moderateAlt]: 'e-text-primary-300',
    [TagV2Appearance.strong]: 'e-text-primary-300',
  },
  [TagV2Type.warning]: {
    [TagV2Appearance.subtle]: 'e-text-warning-500',
    [TagV2Appearance.moderate]: 'e-text-warning-400',
    [TagV2Appearance.moderateAlt]: 'e-text-warning-400',
    [TagV2Appearance.strong]: 'e-text-warning-200',
  },
  [TagV2Type.error]: {
    [TagV2Appearance.subtle]: 'e-text-error-500',
    [TagV2Appearance.moderate]: 'e-text-error-400',
    [TagV2Appearance.moderateAlt]: 'e-text-error-400',
    [TagV2Appearance.strong]: 'e-text-error-500',
  },
};

export interface TagV2Props extends HTMLAttributes<HTMLDivElement> {
  /**
   * Set the Tag content
   */
  children: ReactNode;

  /**
   * Changes the size of the tag, giving it more or less padding
   */
  size?: TagV2Size;

  /**
   * The shape of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  type?: TagV2Type;

  /**
   * The appearance of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  appearance?: TagV2Appearance;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The icon to display on the left side.
   */
  startIcon?: IconCatalog;

  /**
   * Whether the Tag has a close button
   */
  isDismissable?: boolean;

  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onCloseClick?: (event?: React.SyntheticEvent) => void;

  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;
}

/**
 * The Tag component is useful to emphasize information to the user, works best with single word values.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-08-02
 */
export const TagV2 = ({
  children,
  startIcon,
  size = TagV2Size['xs'],
  type = TagV2Type.info,
  appearance = TagV2Appearance.subtle,
  isDismissable = false,
  className,
  dataTestId,
  onCloseClick,
  ...restProps
}: TagV2Props) => {
  const [tagContent, setTagContent] = useState('');

  const classes = {
    tag: cn(
      className,
      'e-items-center e-relative e-overflow-hidden',
      'e-text-center e-whitespace-nowrap',
      'e-transition e-duration-100 e-ease-out',
      'e-rounded-full',
      Variants[type][appearance],
      Sizes[size],
      {
        'e-inline-flex': tagContent.length > 1,
        'e-w-6 e-h-6 e-flex e-justify-center e-flex-grow-0 e-flex-shrink-0': tagContent.length === 1,
      },
    ),
    label: cn('e-truncate e-font-medium', LabelSizes[size]),
    startIcon: cn('e-flex-none e-mr-1', IconVariants[type][appearance]),
    endIcon: cn('e-cursor-pointer e-flex-none', IconVariants[type][appearance], {
      'e-ml-1': isDismissable,
    }),
  };

  const handleCloseBtnClick = (event: React.SyntheticEvent) => {
    if (onCloseClick) onCloseClick(event);
  };

  const handleTagRef = (ref: HTMLDivElement | null) => {
    if (!ref) return;
    setTagContent(ref.textContent as string);
  };

  /* Render JSX */
  return (
    <div {...restProps} ref={(ref) => handleTagRef(ref)} data-testid={dataTestId} className={classes.tag}>
      {startIcon && (
        <Icon className={classes.startIcon} icon={startIcon} width={IconSizes[size]} height={IconSizes[size]} />
      )}
      <span className={classes.label}>{children}</span>
      {isDismissable && (
        <Icon
          className={classes.endIcon}
          icon={IconCatalog.close}
          width={IconSizes[size]}
          height={IconSizes[size]}
          onClick={handleCloseBtnClick}
        />
      )}
    </div>
  );
};
