import React from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../Icon/Icon';

const NOTIFICATION_DOT_SIZE = 8;

const BellWithDot = () => (
  <div className="e-relative">
    <Icon
      icon={IconCatalog.circleSolid}
      className="e-text-error-500 e-absolute e-top-[3px] e-right-[3px]"
      width={NOTIFICATION_DOT_SIZE}
      height={NOTIFICATION_DOT_SIZE}
    />
    <Icon icon={IconCatalog.bellBite} />
  </div>
);

export interface ProfileButtonProps {
  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Set the user's full name
   */
  userFullname: string;

  /**
   * Set the laboratory's name
   */
  laboratoryName: string;

  /**
   * Whether the user has notifications or not
   */
  hasNotifications?: boolean;

  /**
   * The callback to get notified when the button was clicked
   */
  onClick?: () => void;

  variant?: ProfileButtonVariant;
}

export enum ProfileButtonVariant {
  default = 'default',
  white = 'white',
}

const ColorVariants: Record<ProfileButtonVariant, string> = {
  [ProfileButtonVariant.default]: 'e-text-primary-300',
  [ProfileButtonVariant.white]: 'e-text-base-white',
};

/**
 * This component is used to display the user's profile information
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-08-14
 */
export const ProfileButton = ({
  className,
  userFullname,
  laboratoryName,
  variant = ProfileButtonVariant.default,
  hasNotifications = false,
  onClick,
}: ProfileButtonProps) => {
  const classes = {
    container: cn(
      className,
      'e-flex e-items-center e-justify-between e-transition-colors e-duration-500',
      'e-p-2 e-w-full',
      'e-rounded-lg hover:e-bg-neutral-700 e-cursor-pointer',
      [ColorVariants[variant]],
    ),
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  /* Render JSX */
  return (
    <div role="button" tabIndex={0} className={classes.container} onClick={handleClick} onKeyDown={handleClick}>
      <div className="e-flex e-items-center e-gap-4">
        {hasNotifications ? <BellWithDot /> : <Icon icon={IconCatalog.bell} />}
        <div className="e-flex e-flex-col e-gap-1">
          <span className="e-text-sm e-font-bold">{userFullname}</span>
          <span className="e-text-xs e-font-regular">{laboratoryName}</span>
        </div>
      </div>
      <Icon icon={IconCatalog.chevronDown} />
    </div>
  );
};
