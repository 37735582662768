import React from 'react';
import { ICON_SIZE_DEFAULT } from '../../../common';
import { AlertCard, AlertCardProps, AlertCardVariant } from '../../AlertCard/AlertCard';
import { IconCatalog } from '../../Icon/Icon';

interface ErrorStateProps extends Omit<AlertCardProps, 'icon' | 'iconSize' | 'variant'> {}

export const ErrorState = (props: ErrorStateProps) => {
  return (
    <AlertCard
      {...props}
      icon={IconCatalog.warningTriangle}
      iconSize={ICON_SIZE_DEFAULT}
      variant={AlertCardVariant.error}
    />
  );
};
