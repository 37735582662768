import React from 'react';
import cn from 'classnames';

const DEFAULT_DIVIDER_SPACING = 6;

export enum DividerVariant {
  subtle,
  strong,
}

export interface DividerProps {
  /**
   * Variant to use for the `Divider`
   */
  variant?: DividerVariant;

  /**
   * Amount of space the `Divider` will use
   */
  spacing?: number;
}

/**
 * Use `Divider` to create separators in between the content
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 */
export const Divider = ({ variant = DividerVariant.subtle, spacing = DEFAULT_DIVIDER_SPACING }: DividerProps) => {
  const classes = {
    divider: cn(`e-my-${spacing}`, {
      'e-border-neutral-500': variant === DividerVariant.subtle,
      'e-border-neutral-300': variant === DividerVariant.strong,
    }),
  };

  return <hr className={classes.divider} />;
};
