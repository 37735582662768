import React from 'react';
import cn from 'classnames';

export enum SpinnerSize {
  xs = 'xs',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

const Sizes: Record<SpinnerSize, { width: string; height: string }> = {
  [SpinnerSize.xxl]: { width: '96px', height: '96px' },
  [SpinnerSize.xl]: { width: '64px', height: '64px' },
  [SpinnerSize.lg]: { width: '48px', height: '48px' },
  [SpinnerSize.xs]: { width: '16px', height: '16px' },
};

export enum SpinnerVariant {
  primary = 'primary',
  neutral = 'neutral',
}

const Variants: Record<SpinnerVariant, string> = {
  [SpinnerVariant.primary]: 'e-fill-primary-500',
  [SpinnerVariant.neutral]: 'e-fill-base-white',
};

export interface SpinnerProps {
  /**
   * Class names used for external styles
   */
  className?: string;

  /**
   * External styles of wrapper spinner
   */
  style?: React.CSSProperties;

  /**
   * Changes the size of the Spinner.
   */
  size?: SpinnerSize;

  /**
   * The variant of the component. It supports those theme colors that makes sense for this component.
   */
  variant?: SpinnerVariant;

  /**
   * Set full width and full height
   */
  isFullScreen?: boolean;
}

/**
 * A Spinner is an outline of a circle which animates around itself indicating to the user that things are processing.
 * Use a Spinner when the content to be loaded is unknown or unpredictable.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created at 2022-08-19
 */
export const Spinner = ({
  className,
  variant = SpinnerVariant.primary,
  size = SpinnerSize.lg,
  style = {},
  isFullScreen = false,
}: SpinnerProps) => {
  const classes = {
    spinner: cn(className, 'e-flex e-items-center e-justify-center', {
      'e-h-full e-w-full': isFullScreen,
    }),
    stroke: cn('e-ease-linear e-animate-spin', Variants[variant]),
  };

  return (
    <div aria-label="spinner" role="status" style={style} className={classes.spinner}>
      <svg
        width={Sizes[size].width}
        height={Sizes[size].height}
        viewBox="0 0 96 96"
        className={classes.stroke}
        fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 12C27.9825 12 12 27.9825 12 48C12 68.0175 27.9825 84 48 84C68.0175 84 84 68.0175 84 48C84 27.9825 68.0175 12 48 12ZM0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z"
          className="e-fill-neutral-500"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90 48C86.6863 48 84.0524 45.2935 83.5065 42.0251C80.985 26.9298 69.0702 15.015 53.9749 12.4935C50.7065 11.9476 48 9.31371 48 6C48 2.68629 50.6997 -0.039338 53.9881 0.369878C75.71 3.07305 92.927 20.29 95.6301 42.0119C96.0393 45.3003 93.3137 48 90 48Z"
        />
      </svg>
    </div>
  );
};
