import React from 'react';
import cn from 'classnames';
import { Label as RadixLabel, MenuLabelProps } from '@radix-ui/react-dropdown-menu';

export const Label = React.forwardRef<HTMLDivElement, MenuLabelProps>((props, forwardedRef) => {
  const { className, ...rest } = props;
  const classes = {
    container: cn('e-text-xs e-text-neutral-300 e-py-3 e-px-4 e-text-left e-font-light', className),
  };
  return <RadixLabel className={classes.container} ref={forwardedRef} {...rest} />;
});
