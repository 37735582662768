import React, { useCallback, useState } from 'react';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import { Radio } from '../../../Controls/Radio/Radio';
import { Locale, LocaleLabel, SelectOption, Translations } from '../../../../common';

const translations: Translations = {
  en: {
    languagePreference: 'Language Preference',
  },
  es: {
    languagePreference: 'Preferencias de idioma',
  },
  pt: {
    languagePreference: 'Preferência de idioma',
  },
};

export interface LanguageSelectorProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * The callback to get notified when the language is changed
   */
  onChange?: (language: Locale) => void;
}

/**
 * `LanguageSelector` represents the selector where the language can be changed.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-04-05
 */
export const LanguageSelector = ({ locale = Locale.es, onChange }: LanguageSelectorProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // build language options
  const languageOptions: Array<SelectOption> = Object.keys(Locale).map((key) => ({
    value: key,
    label: LocaleLabel[locale][key as Locale],
  }));

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const language = event.target.value;
    if (onChange) onChange(language as Locale);
  };

  const handleToggleCollapsed = useCallback(() => setIsExpanded((prevExpanded) => !prevExpanded), [setIsExpanded]);

  return (
    <div className="e-flex e-flex-col e-space-y-3">
      <div
        role="button"
        tabIndex={0}
        className="e-flex e-justify-between e-space-x-3 e-w-full e-items-center e-py-2 e-cursor-pointer"
        onClick={handleToggleCollapsed}
        onKeyDown={handleToggleCollapsed}>
        <span className="e-text-neutral-200 e-text-sm e-semibold">{translations[locale].languagePreference}</span>
        <Icon className="e-text-neutral-200" icon={isExpanded ? IconCatalog.chevronUp : IconCatalog.chevronDown} />
      </div>
      {isExpanded && (
        <div className="e-flex e-flex-col e-space-y-5">
          {languageOptions.map(({ value, label }) => (
            <Radio
              key={`language-${value}`}
              value={value}
              onChange={handleLanguageChange}
              checked={locale === value}
              label={label}
            />
          ))}
        </div>
      )}
    </div>
  );
};
