import React, { ReactNode } from 'react';
import cn from 'classnames';

export interface NavbarProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Elements to display inside the Navbar.
   */
  children?: ReactNode;
}

/**
 * Navbar is the building block for creating the global navigation bar for your experience.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-06-16
 */
export const Navbar = ({ ariaLabel, className, children }: NavbarProps) => {
  const classes = cn(
    className,
    'e-flex e-items-center e-px-4 e-py-6 e-bg-neutral-900 e-border-b e-border-b-neutral-600 e-h-20',
  );

  return (
    <nav aria-label={ariaLabel} className={classes}>
      {children}
    </nav>
  );
};
