import React from 'react';
import cn from 'classnames';
import { Separator as RadixSeparator, MenuSeparatorProps } from '@radix-ui/react-dropdown-menu';

export interface SeparatorProps extends MenuSeparatorProps {}

export const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>((props, forwardedRef) => {
  const { className, ...rest } = props;
  const classes = {
    container: cn('e-h-0 e-border-neutral-500 e-border-b', className),
  };
  return <RadixSeparator className={classes.container} ref={forwardedRef} {...rest} />;
});
