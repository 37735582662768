import React, { ReactNode, ButtonHTMLAttributes, forwardRef } from 'react';
import { cn } from '../../../common/utils';
import { Icon, IconCatalog } from '../../Icon/Icon';

export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  base = 'base',
}

const SizesWithoutIcon: Record<ButtonSize, string> = {
  [ButtonSize.xs]: 'e-py-2 e-px-4 e-text-sm e-font-medium',
  [ButtonSize.sm]: 'e-py-2 e-px-4 e-text-base e-font-medium',
  [ButtonSize.base]: 'e-p-4 e-text-base e-font-medium',
};

const SizesOnlyIcon: Record<ButtonSize, string> = {
  [ButtonSize.xs]: 'e-p-1 e-text-sm e-font-medium',
  [ButtonSize.sm]: 'e-p-2 e-text-base e-font-medium',
  [ButtonSize.base]: 'e-p-4 e-text-base e-font-medium',
};

const SizesWithStartIcon: Record<ButtonSize, string> = {
  [ButtonSize.xs]: 'e-pl-1 e-pr-2 e-py-1 e-text-sm e-font-medium',
  [ButtonSize.sm]: 'e-pl-2 e-pr-4 e-py-2 e-text-base e-font-medium',
  [ButtonSize.base]: 'e-pl-2 e-pr-4 e-py-4 e-text-base e-font-medium',
};

const SizesWithEndIcon: Record<ButtonSize, string> = {
  [ButtonSize.xs]: 'e-pl-2 e-pr-1 e-py-1 e-text-sm e-font-medium',
  [ButtonSize.sm]: 'e-pl-4 e-pr-2 e-py-2 e-text-base e-font-medium',
  [ButtonSize.base]: 'e-pl-4 e-pr-2 e-py-4 e-text-base e-font-medium',
};

export enum ButtonVariant {
  primary = 'primary',
  primaryNeutral = 'primary neutral',
  secondary = 'secondary',
  tertiary = 'tertiary',
  ghost = 'ghost',
  ghostNeutral = 'ghost neutral',
  destructive = 'destructive',
  tertiaryNeutral = 'tertiary neutral',
}

export enum ButtonContentAlignment {
  center = 'center',
  spaceBetween = 'space-between',
  evenly = 'evenly',
}

const ContentAlignments = {
  [ButtonContentAlignment.center]: 'e-justify-center',
  [ButtonContentAlignment.spaceBetween]: 'e-justify-between',
  [ButtonContentAlignment.evenly]: ' e-justify-evenly',
};

const Variants: Record<ButtonVariant, string> = {
  [ButtonVariant.primary]: 'e-bg-primary-500 e-text-base-white',
  [ButtonVariant.primaryNeutral]: 'e-bg-base-white e-text-base-black',
  [ButtonVariant.secondary]: 'e-bg-transparent e-border e-border-primary-50 e-text-base-white',
  [ButtonVariant.tertiary]: 'e-bg-neutral-700 e-text-primary-300',
  [ButtonVariant.ghost]: 'e-bg-transparent e-text-primary-300',
  [ButtonVariant.ghostNeutral]: 'e-bg-transparent e-text-base-white',
  [ButtonVariant.destructive]: 'e-bg-error-600 e-text-base-white',
  [ButtonVariant.tertiaryNeutral]: 'e-bg-base-white e-bg-opacity-10  e-text-base-white',
};

const HoverVariants: Record<ButtonVariant, string> = {
  [ButtonVariant.primary]: 'hover:e-bg-primary-600',
  [ButtonVariant.primaryNeutral]: 'hover:e-bg-neutral-200 e-text-base-black',
  [ButtonVariant.secondary]: 'hover:e-bg-base-white/20',
  [ButtonVariant.tertiary]: 'hover:e-bg-neutral-600',
  [ButtonVariant.ghost]: 'hover:e-bg-neutral-600',
  [ButtonVariant.ghostNeutral]: 'hover:e-bg-base-white/10',
  [ButtonVariant.destructive]: 'hover:e-bg-error-700',
  [ButtonVariant.tertiaryNeutral]: 'hover:e-bg-opacity-20',
};

const ActiveVariants: Record<ButtonVariant, string> = {
  ...Variants,
  [ButtonVariant.primaryNeutral]: 'e-bg-neutral-500 e-text-base-white',
  [ButtonVariant.tertiary]: 'e-bg-primary-900 e-text-primary-300',
  [ButtonVariant.ghostNeutral]: 'e-bg-transparent e-text-primary-300',
  [ButtonVariant.tertiaryNeutral]: 'e-bg-primary-900 e-text-primary-300',
};

const HoverActiveVariants: Record<ButtonVariant, string> = {
  ...HoverVariants,
  [ButtonVariant.tertiary]: 'hover:e-bg-opacity-20',
  [ButtonVariant.ghostNeutral]: 'hover:e-bg-neutral-600',
  [ButtonVariant.tertiaryNeutral]: 'hover:e-bg-opacity-20',
};

const DisabledVariants: Record<ButtonVariant, string> = {
  [ButtonVariant.primary]: 'e-bg-primary-700 e-text-neutral-300',
  [ButtonVariant.primaryNeutral]: 'e-bg-neutral-300 e-text-neutral-500',
  [ButtonVariant.secondary]: 'e-bg-transparent e-border e-border-r-neutral-300 e-text-neutral-300',
  [ButtonVariant.tertiary]: 'e-bg-transparent e-text-neutral-300',
  [ButtonVariant.ghost]: 'e-bg-transparent e-text-neutral-300',
  [ButtonVariant.ghostNeutral]: 'e-bg-transparent e-text-neutral-300',
  [ButtonVariant.destructive]: 'e-bg-error-800 e-text-neutral-300',
  [ButtonVariant.tertiaryNeutral]: 'e-bg-transparent e-text-base-white/30',
};

enum ButtonIconSize {
  xs = 24,
  sm = 24,
  base = 24,
}

export enum HtmlType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Changes the size of the button, giving it more or less padding
   */
  size?: ButtonSize;
  /**
   * Changes the button content alignment. p.e. center, space between.
   */
  contentAlignment?: ButtonContentAlignment;

  /**
   * The shape of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  variant?: ButtonVariant;

  /**
   * The icon to display on the left side.
   */
  startIcon?: IconCatalog;

  /**
   * The icon to display on the right side.
   */
  endIcon?: IconCatalog;

  /**
   * Disables the button, disallowing user interaction.
   */
  isDisabled?: boolean;

  /**
   * Active version of the button.
   */
  isActive?: boolean;

  /**
   * Active version of the button when hover.
   */
  isHoverActive?: boolean;

  /**
   * If set to true, the button will display a loading effect.
   */
  isLoading?: boolean;

  /**
   * Set a diferent loading text for your button.
   */
  loadingText?: string;

  /**
   * Extends the button to 100% width.
   */
  isFullWidth?: boolean;

  /**
   * HTML type attribute of the button.
   */
  htmlType?: HtmlType;

  /**
   * Elements to display inside the Navbar.
   */
  children?: ReactNode;
}

/**
 * Buttons are used to initialize an action. Button labels express what action will occur when the user interacts with it.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-05-24
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      size = ButtonSize.base,
      startIcon,
      endIcon,
      isDisabled = false,
      isLoading = false,
      isActive = false,
      isHoverActive = true,
      loadingText,
      contentAlignment = ButtonContentAlignment.center,
      isFullWidth = false,
      variant = ButtonVariant.primary,
      htmlType = HtmlType.button,
      className,
      onClick,
      ...restOfProps
    },
    ref,
  ) => {
    const setSizes = () => {
      if (startIcon && children) return SizesWithStartIcon[size];
      if (endIcon && children) return SizesWithEndIcon[size];
      if ((startIcon || endIcon) && !children) return SizesOnlyIcon[size];

      return SizesWithoutIcon[size];
    };

    const setVariants = () => {
      if (isDisabled) return DisabledVariants[variant];
      if (isActive) return ActiveVariants[variant];

      return Variants[variant];
    };

    const setHoverStyles = () => {
      if (isActive && isHoverActive) return HoverActiveVariants[variant];
      if (isHoverActive) return HoverVariants[variant];
    };

    const classes = {
      button: cn(
        'e-flex e-items-center e-relative e-overflow-hidden e-min-w-fit e-box-content',
        'e-text-center e-whitespace-nowrap',
        'e-transition e-duration-100 e-ease-out',
        'e-rounded-lg',
        setSizes(),
        setVariants(),
        setHoverStyles(),
        ContentAlignments[contentAlignment],
        {
          'e-w-full': isFullWidth,
        },
        className,
      ),
      startIcon: cn({
        'e-mr-1': children && size === ButtonSize.xs,
        'e-mr-2': children && (size === ButtonSize.sm || size === ButtonSize.base),
      }),
      endIcon: cn({
        'e-ml-1': children && size === ButtonSize.xs,
        'e-ml-2': children && (size === ButtonSize.sm || size === ButtonSize.base),
      }),
      loading: cn(
        'e-absolute e-left-0 e-top-0 e-opacity-30',
        'e-w-full e-h-full',
        'e-flex e-items-center e-justify-center',
        'after:e-content-[""] after:e-absolute after:e-h-full after:e-w-full after:e-animate-translation-x',
        'before:e-content-[""] before:e-absolute before:e-h-full before:e-w-full before:e-animate-translation-x',
        {
          'after:e-bg-primary-700 before:e-bg-primary-700': variant === ButtonVariant.primary,
          'after:e-bg-neutral-500 before:e-bg-neutral-500':
            variant === ButtonVariant.secondary ||
            variant === ButtonVariant.tertiary ||
            variant === ButtonVariant.ghost,
          'after:e-bg-error-700 before:e-bg-error-700': variant === ButtonVariant.destructive,
        },
      ),
    };

    /* Render JSX */
    return (
      <button
        ref={ref}
        className={classes.button}
        type={htmlType}
        disabled={isDisabled || isLoading}
        onClick={onClick}
        {...restOfProps}>
        {startIcon && (
          <Icon
            className={classes.startIcon}
            icon={startIcon}
            width={ButtonIconSize[size]}
            height={ButtonIconSize[size]}
          />
        )}
        <span>{children}</span>
        {isLoading && <span className={classes.loading}></span>}
        {endIcon && (
          <Icon className={classes.endIcon} icon={endIcon} width={ButtonIconSize[size]} height={ButtonIconSize[size]} />
        )}
      </button>
    );
  },
);
